import React from 'react';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Clear';
import classes from './Modal.module.css';
import Hoc from '../../../hoc/hoc';
import Backdrop from '../Backdrop/Backdrop';

const modal = (props) => (
	<Hoc>
		<Backdrop show={props.show} clicked={props.modalClosed} />
			<Paper 
				style={{
					transform: props.show ? 'translateY(-50%)' : 'translateY(-200vh)',
					opacity: props.show ? '1' : '0'
				}}
				className={classes.Modal} elevation={1}
			>
				{props.children}
				<div className={classes.closeButton}>
					<IconButton aria-label="Delete"
						onClick={props.modalClosed}
					>
						<CloseIcon />
					</IconButton>
				</div>
			</Paper>

	</Hoc>
);

export default modal;