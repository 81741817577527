import React, { Component } from 'react';
import styles from './MessageSend.module.css';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Hoc from '../../hoc/hoc';

import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

class MessageSend extends Component {

	state = {
		filled:false,
		maxCharacters:300,
		charactersUsed:0,
		fieldValue:'',
		messageSent:false,
		formType:0,
		invalidForm:false
	}
	
    componentDidMount = () => {
        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isAlphaNumericSpace', (value) => {
			let patt = /^[a-zA-Z0-9\s.,'-\s!\s?]*$/i;
			return patt.test(value);
        });
	}

	handleChange = (event) => {
		const {name,value} = event.target;
		let newState = {
			...this.state,
			[name]:value
		}
		this.setState(newState);
	};


	changeField = (event) => {
		let newFilled = null;
		if (event.target.value.length>this.state.maxCharacters) {
			this.setState({
				...this.state,
				filled:true
			});
			return;
		} else {
			if (this.state.filled===true) {
				newFilled = false;
			}
			this.setState({
				...this.state,
				fieldValue: event.target.value,
				charactersUsed: event.target.value.length,
				filled: newFilled
			});
		}
	}

	doSendMessage = (event) => {
		event.preventDefault();
		var ts = new Date().getTime();
		var pano = this.props.panoNumber;

		this.props.onSendMessage(JSON.stringify(this.state.fieldValue), pano, this.props.userID, ts);
	}

	componentWillUnmount = () => {
		this.closePano();
	}

	closePano = () => {
		var newState = {
			...this.state,
			filled:false,
			charactersUsed:0,
			fieldValue:'',
			messageSent:false,
			formType:0
		}
		this.setState(newState);
		this.props.onResetMessage();
		this.props.closePanorama();		
	}

    render () {

		const stanje = this.state.charactersUsed + " : " + this.state.maxCharacters;
		let labelRed = false;
		if (this.state.filled) {
			labelRed=true;
		}
		let switchDisable = null
		if (this.state.charactersUsed===0 || this.state.messageSnt || this.state.invalidForm || !/^[a-zA-Z0-9\s.,'-\s!\s?]*$/i.test(this.state.fieldValue)) {
			switchDisable=true;
		}

		let componentBody;
		let componentButton;
		let messageText;

		//console.log(this.props.formType);

		if (this.props.formType===0) {
			componentButton = (
				<Button variant="contained" color="secondary" className={styles.button} disabled={switchDisable} type='submit'
					onClick={this.doSendMessage}
					>
					Pošalji opravdanje
				</Button>				
			)
			componentBody = (
				<Hoc>
					<Typography
						align='center'
						gutterBottom
						variant="subheading"
						>
							Opravdajte se porukom do 300 karaktera
					</Typography>
					{/*}
				<TextField
					id="outlined-multiline-static"
					label={stanje}
					multiline
					onChange={(e) => this.changeField(e)}
					rows="8"
					className={styles.textField}
					margin="normal"
					variant="outlined"
					value={this.state.fieldValue}
				/>
				*/}

				<ValidatorForm
					ref="form"
					onSubmit={this.doSendMessage}
					onError={errors => console.log(errors)}
					className={styles.form}
				>
					<TextValidator
						id="outlined-multiline-static"
						label={stanje}
						name="poruka"
						multiline
						rows="8"
						helperText=""
						className={styles.textField}
						margin="normal"
						autoComplete="off"
						variant="outlined"
						value={this.state.fieldValue}
						onChange={this.changeField}
						validators={['isAlphaNumericSpace']}
						errorMessages={['Uneseni su nedozvoljeni karakteri']}
					/>
					{componentButton}
				</ValidatorForm>
				</Hoc>
			);			
		} else {
			if (this.props.formType===1) {
				messageText = (
					<Hoc>
						<h2>Poruka je uspešno poslana!</h2>
						<h3>Po odobrenju, potražite je u Galeriji opravdanja.</h3>
					</Hoc>
				)
			} else if (this.props.formType===2){
				messageText = (
					<Hoc>
						<h2>Došlo je do greške pri slanju poruke!</h2>
						<h3>Pokušajte ponovo kasnije.</h3>
					</Hoc>
				)
			}

			componentButton = (
				<Button variant="contained" color="secondary" className={styles.button}
					onClick={this.closePano}
					>
					Zatvori prozor
				</Button>				
			)

			componentBody = (
				<Hoc>
					{messageText}
					<div style={{textAlign:'center'}}>{componentButton}</div>
				</Hoc>
			)

		}

        return (
			
			<div className={styles.messageSendWindow}>
				<div className={styles.messageTitle}>
					<Typography 
						align='center'
						gutterBottom 
						variant='title'>
							Objasni dedi
					</Typography>
				</div>
				<div className={labelRed ? styles.fieldFilled : styles.fieldNotFilled}>
					{componentBody}
				</div>
					{}
			</div>			

        );
    }
};


const mapStateToProps = state => {
	return {
		authToken: state.auth.token,
		userID: state.auth.userId,
		panoNumber: state.my.panoramaNumber,
		messageSendStatus: state.comm.messageSendStatus,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onResetMessage: () => dispatch( actions.messageResult(0) ),
		onSendMessage: (msg, pano, uid, ts) => dispatch (actions.sendMessage(msg,pano,uid,ts)),
		onUpdateModal: (modState, modWhat) => dispatch( actions.changeModal(modState, modWhat) ),
		onLogout: () => dispatch (actions.logout() ),
		onUpdatePath: (newpath) => dispatch (actions.changePath(newpath)),
	};
};


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(MessageSend));