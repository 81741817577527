import React, { Component } from 'react';

import Layout from './components/Layout/Layout';

import LandingPage from './containers/LandingPage/LandingPage';

import Profile from './containers/Profile/Profile';
import Kako from './containers/Kako/Kako';
import Objasni from './containers/Objasni/Objasni';
import Rules from './containers/Rules/Rules';
import GalerijaOpravdanja from './containers/GalerijaOpravdanja/GalerijaOpravdanja';
import Dobitnici from './containers/Dobitnici/Dobitnici';

import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from './store/actions/index'


class App extends Component {

  componentDidMount () {
    this.props.onTryAutoSignup();
  }

  render() {

    let routes = (
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/kako-da-ucestvujem" exact component={Kako} />
        <Route path="/objasni-dedi" exact component={Objasni} />
        <Route path="/galerija-opravdanja" exact component={GalerijaOpravdanja} />
        <Route path="/dobitnici" exact component={Dobitnici} />
        <Route path="/profil" exact component={Profile} />
        <Route path="/pravila" exact component={Rules} />
        <Redirect to="/" />
      </Switch>
    );

    return (
      <Layout path={this.props.curPath}>
        {routes}
      </Layout>
    );
  }
}

const mapStateToProps = state => {
	return {
    isAuthenticated: state.auth.token !== null,
    curPath: state.my.currentPath,
	};
}


const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch( actions.authCheckState() )
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));