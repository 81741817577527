import React, { Component } from 'react';
import classes from './Rules.module.css';

//import scrollToComponent from 'react-scroll-to-component';
//import ScrollTrigger from 'react-scroll-trigger';

import HeaderTitle from '../../components/UI/HeaderTitle/HeaderTitle';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Footer from '../Footer/Footer';
import { withRouter } from 'react-router-dom';

import ReactGA from 'react-ga';

/* redux stuff*/
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import Hoc from '../../hoc/hoc';

class Rules extends Component {

	
	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onUpdatePath('');
	}

	/*
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.lpElement);
		if (nextProps.lpElement!=="") {
			scrollToComponent(this['zero'], {align:'top',offset:-117,ease:'out-quad', duration:500})
			this.props.onUpdateLPElement("");
		}
	}

	onEnterViewport = (nr) => {
		this.props.onUpdateLPPosition(nr);
	}
	*/

	render () {

		return (

            <Hoc>
			<HeaderTitle title="Pravila"/>
            <Grid container spacing={24}>
                <Grid item xs>
                </Grid>
                <Grid item sm={8} xs={10} className={classes.rulesHolder}>
					<Paper className={classes.paper}>
						<h1>Deda Mraze, dozvoli da objasnim</h1>
						<h2>Pravila i uslovi učestvovanja u konkursu</h2>
		
						<p>Ovaj  konkurs/kampanja je u organizaciji društva za marketing SmartPoint Adria  d.o.o., Mijačka 3, 11000 Beograd, Srbija, matični broj 20776650 (&ldquo;SmartPoint  Adria&rdquo; ili &ldquo;Organizator&rdquo;), uz ovlašćenje kompanije Reckitt Benckiser (Mađarska)  Kft, Budimpešta, Mađarska (&bdquo;Reckitt Benckiser&ldquo;), u skladu sa Članom 232 Zakona  o obligacionim odnosima – Slučaj konkursa. U cilju eliminisanja bilo kakve  zabune, ovaj konkurs/kampanja, kao i Pravila i uslovi učestvovanja, ne sadrže  elemente igre na sreću (sa nagradnim fondom u robi ili uslugama) i ne podleže  odredbama Zakona o igrama na sreću. </p>
						<ol>
						<li>Da biste kao takmičar učestvovali u konkursu/kampanji &ldquo;Deda Mraze, dozvoli da objasnim&rdquo;, morate u potpunosti pročitati i  prihvatiti naša Pravila i uslove učestvovanja u konkursu. </li>
						<li>Zaposleni/angažovani u agenciji SmartPoint Adria i  kompaniji Reckitt Benckiser, kao i njihova rodbina do 3. stepena srodstva  nemaju pravo učešća u ovom konkursu/kampanji. </li>
						<li>Konkurs/kampanja &ldquo;Deda Mraze, dozvoli da objasnim&rdquo;  počinje u ponedeljak 19.11.2018. i traje do petka, 28.12.2018. do 23:59:59 po lokalnom vremenu.</li>
						<li>U konkursu/kampanji &ldquo;Deda Mraze, dozvoli da objasnim&rdquo;  mogu učestvovati isključivo lica starija od 18 godina. Konkurs/kampanja &ldquo;Deda Mraze, dozvoli da objasnim&rdquo; dostupan je klikom na link <a href="https://www.objasnidedi.rs">www.objasnidedi.rs.</a>     </li>
						<li>Da biste učestvovali u konkursu/kampanji  &ldquo;Deda Mraze, dozvoli da objasnim&rdquo; neophodno je učiniti sledeće: ukucati <a href="https://www.objasnidedi.rs">www.objasnidedi.rs</a> direktno u pretraživaču i pritisnuti  taster &ldquo;Enter&rdquo;, ili kliknuti na link <a href="https://www.objasnidedi.rs">www.objasnidedi.rs</a> koji su podelili administratori na  Facebook stranici <a 
							href="https://www.facebook.com/Durex.Srbija" target="_blank"  rel="noopener noreferrer"
							onClick= { () => {
								ReactGA.event({
									category: 'Navigation',
									action: 'Clicked Facebook Link - Rules'
								});
							}}
							>Durex Srbija</a>, otvoriti i  koristiti aplikaciju na veb-sajtu.</li>
						<li>Konkurs &ldquo;Deda Mraze, dozvoli da objasnim&rdquo; omogućava učesnicima da izaberu jednu od tri ponuđene slike i napišu originalnu poruku u kojoj će objasniti šta je prethodilo sceni prikazanoj na slici.<br/><br/>
						
						Stručni žiri koji čine 3 člana iz kompanije Reckitt Benckiser i agencije SmartPoint Adria, proglasiće dobitnike na osnovu sledećih kriterijuma: kreativnost, originalnost, duhovitost i opšti utisak koji je u skladu sa vrednostima brenda Durex. 
						<br/><br/>

							<h3>NAGRADE (DNEVNE, NEDELJNE,  GLAVNA)</h3>
							<h4>DNEVNE  NAGRADE:</h4>
						<p>  40 dnevnih nagrada u vidu paketića koji sadrži:</p>
						<ul>
						<li>	1 x ljubavni žetoni</li>
						<li> 	1 x kalup za led u obliku srca</li>
						<li>	1 x Durex Intense gel 10 ml</li>
						</ul>
		
							<p>Vrednost  dnevne nagrade iznosi <strong>2,510 RSD</strong>, sa PDV-om</p>
		

						<h4>GLAVNA NAGRADA:</h4>
							<ul>
								<li>
								1 glavna nagrada u vidu putovanja za dve osobe u Amsterdam. Nagrada uključuje dve povratne avionske karte u ekonomskoj klasi (prtljag uključen) na relaciji Beograd-Amsterdam-Beograd, smeštaj u hotelu sa 3 zvezdice na bazi tri noćenja sa doručkom, transfer na relaciji aerodrom-hotel/hotel-aerodrom, putno zdravstveno osiguranje i džeparac u iznosu od 40 EUR po osobi po danu. Maksimalna vrednost glavne nagrade je <strong>1.500,00 EUR</strong>, sa PDV-om.								
								</li>
							</ul>
							<p>Putovanje će se ostvariti najkasnije šest meseci od datuma validacije dobitnika. SmartPoint Adria će rezervisati aranžman u ime dobitnika glavne nagrade i njegovog/njenog saputnika/saputnicu i u obavezi je da dostavi sve neophodne detalje u vezi sa aranžmanom putovanja. Aranžman se mora rezervisati najkasnije mesec dana unapred.</p>

							<p>Kako bi mogao da iskoristi nagradu, dobitnik treba da saopšti željeni datum putovanja u roku od 30 kalendarskih dana od datuma objavljivanja dobitnika glavne nagrade.</p>
						</li>
		
						<li>Mehanika dodeljivanja nagrada u okviru konkursa
							<p>Da  bi kao takmičar učestvovao u konkursu/kampanji &ldquo;Deda Mraze, dozvoli da objasnim&rdquo;, učesnik najpre treba da se registruje  na veb-sajtu <a href="https://www.objasnidedi.rs">www.objasnidedi.rs</a> i upiše sledeće podatke: ime, prezime, e-mail adresa i broj telefona. Podaci će  biti korišćeni isključivo u svrhe koje slede u nastavku:</p>
							<ul>
							<li>Identifikacija dobitnika nagrada </li>
							<li>Objava imena i prezimena dobitnika nagrada na veb-sajtu <a href="https://www.objasnidedi.rs">www.objasnidedi.rs</a>
							</li>
							<li>Kontaktiranje dobitnika u vezi sa preuzimanjem nagrada, pri čemu će od dobitnika dnevne nagrade biti zatražena adresa za dostavu nagrade, dok će od dobitnika glavne nagrade biti zatraženi podaci neophodni isključivo za rezervaciju aranžmana.</li>
							</ul>
							<p>Od učesnika/ce se očekuje da napiše originalnu poruku na osnovu izabrane slike prostorije u kojoj će objasniti događaje koji su prethodili sceni prikazanoj na slici. Zadatak za učesnike je da smisle opravdanje za Deda Mraza, odnosno da mu objasne da nisu odgovorni za dokaze na slici i ubede ga da zaslužuju poklon. 
							Za potrebe pisanja i slanja poruke, učesniku/ci će se prikazati polje za unos teksta kada izabere scenu. Nakon što poruka bude odobrena od strane administratora, ista će se pojaviti u sekciji “Galerija opravdanja” na veb-sajtu <a href="https://www.objasnidedi.rs">www.objasnidedi.rs</a>.
							</p>
							<p>&nbsp;</p>
							<p>Pobednici će se proglašavati na osnovu zadatih kriterijuma: kreativnost, originalnost, duhovitost i opšti utisak koji je u skladu sa vrednostima brenda Durex, dinamikom koja sledi dalje u tekstu: </p>
							<h3>      Za DNEVNE NAGRADE:</h3>
							<p>      svakog dana, jedan od autora poruke koja je prispela tokom prethodnog dana (od trenutka slanja poruke do 23:59:59 istog dana) biće dobitnik dnevne nagrade, na osnovu odluke članova žirija.<br/>
							</p>
							<h3>Za GLAVNU NAGRADU:</h3>
							<p>Krajnji rok za slanje poruka je 28. decembar 2018. u 23:59:59. Nakon završetka roka za slanje poruka, žiri će u narednih 48h proglasiti dobitnika glavne nagrade, uzimajući u obzir sve poruke poslate od početka konkursa.
							Imena dobitnika nagrada će biti objavljena u sekciji “Dobitnici” na veb-sajtu www.objasnidedi.rs. Organizator će dobitnike nagrada kontaktirati putem e-maila ili SMS poruke u roku od 24h od datuma objavljivanja dobitnika i zatražiti adresu za dostavu nagrade u slučaju dnevnih nagrada, odnosno podatke neophodne za realizaciju putovanja u slučaju glavne nagrade.
							</p>
							<p>Imena dobitnika nagrada će biti objavljena u sekciji “Dobitnici” na veb-sajtu <a href="https://www.objasnidedi.rs">www.objasnidedi.rs</a>. Organizator će dobitnike nagrada kontaktirati putem e-maila ili SMS poruke u roku od 24h od datuma objavljivanja dobitnika i zatražiti adresu za dostavu nagrade u slučaju dnevnih nagrada, odnosno podatke neophodne za realizaciju putovanja u slučaju glavne nagrade.</p>
							<p>U slučaju da ne dobije nikakvu povratnu informaciju od dobitnika u roku od 3 kalendarska dana od datuma kontaktiranja, Organizator će objaviti novog dobitnika nagrade prema odluci žirija, pod istim uslovima. Ukoliko i pri drugom pokušaju Organizator ne dobije neophodne podatke za slanje nagrade, istu zadržava agencija SmartPoint Adria i vraća je kompaniji Reckitt Benckiser.
							</p>
						</li>
						<li>Jedan učesnik može osvojiti dnevnu nagradu samo jedanput. Međutim, svi učesnici imaju priliku da osvoje glavnu nagradu, bez obzira na to da li su osvojili dnevnu nagradu ili ne.</li>
						<li>Samo jedan član porodice koja živi na istoj adresi može osvojiti dnevnu nagradu.  </li>
		
						<li>U slučaju da više od jednog dobitnika dostavi istu adresu za slanje dnevne nagrade (mesto, naziv ulice i kućni broj) samo će jedna dnevna nagrada biti poslata na dostavljenu adresu.</li>
						<li>Dostava dnevnih nagrada vršiće se kururskom službom na ime dobitnika lično, u roku od 14 kalendarskih dana od datuma kada dobitnik pošalje podatke za dostavu. </li>
						<li>Ukoliko kurirska služba ne pronađe učesnika na naznačenoj adresi, nagrada će se vratiti Organizatoru. U tom slučaju, Organizator će poslati nagradu još jedanput u roku od 14 dana od datuma kada se pošiljka vratila. Ukoliko učesnik ne preuzme nagradu i drugi put, ili u slučaju da učesnik odbije da preuzme nagradu, nagrada će se vratiti Organizartoru nepovratno.</li>

						<li>Samo punoletni državljani Republike Srbije mogu da osvoje nagrade. Troškovi slanja nagrada su pokriveni na teritoriji Republike Srbije. Nagrade se ne mogu prenositi trećem licu bez saglasnosti Organizatora i kompanije Reckitt Benckiser, i ne mogu se zameniti za novac.</li>
						<li>SmartPoint  Adria and Reckitt Benckiser odgovorni su samo za sadržaj koji su sami proizveli  i postavili.</li>
						<li>Ukoliko postoji sumnja da su učesnici varali kako bi dobili  nagrade, biće diskvalifikovani bez prethodnog obaveštenja i objašnjenja.  Organizator ima pravo da ukloni sav tekst i izjave koje su diskriminišuće,  vulgarne i uvredljive na rasnoj, verskoj ili bilo kojoj drugoj osnovi bez  daljeg objašnjenja. </li>
						<li>SmartPoint  Adria, Reckitt Benckiser i bilo koja treća strana uključena u razvoj i  distribuiranje konkursa &ldquo;Deda Mraze, dozvoli da objasnim&rdquo; ne prihvataju bilo kakvu odgovornost  za bilo kakvu štetu, gubitak ili neispravnost koju učesnik može iskusiti,  uključujući maliciozni softver (viruse) kao i štetu/gubitak/neispravnost usled  preuzimanja bilo kakvih materijala, sadržaja, podataka, tekstova, slika,  fotografija, video i audio materijala sa veb-sajta <a href="https://www.objasnidedi.rs">www.objasnidedi.rs.</a>    </li>
						<li>Durex  konkurs &ldquo;Deda Mraze, dozvoli da objasnim&rdquo;<strong> </strong>se  ne može koristiti niti deliti u komercijalne svrhe i dostupno je samo za ličnu  upotrebu i u gore pomenutu svrhu, u skladu sa Pravilima i uslovima učestvovanja.  Konkurs &ldquo;Deda Mraze, dozvoli da objasnim&rdquo;<strong> </strong>se  ne sme ni na koji način menjati bez pismene saglasnosti kompanije Reckitt  Benckiser i Organitzatora.</li>
						<li>Svi lični podaci učesnika su zaštićeni od strane kompanije Reckitt  Benckiser i agencije SmartPoint Adria i neće biti deljeni niti objavljivani  izvan kompanije Reckitt Benckiser i agencije SmartPoint Adria. Učestvovanjem u konkursu  &ldquo;Deda Mraze, dozvoli da objasnim&rdquo;, učesnici su  svesni i u potpunosti saglasni sa time da ustupaju lične podatke za  sakupljanje/obradu/korišćenje. Lični podaci će biti korišćeni isključivo za  pristup, korišćenje i učestvovanje u konkursu &ldquo;Deda Mraze, dozvoli da objasnim&rdquo;, identifikaciju i slanje nagrada.
		
						<p>Podaci će se čuvati  elektornski u Excel tabeli u trajanju od 60 kalendarskih dana od datuma početka  konkursa, nakon čega će biti trajno obrisani.</p>
						<p>
						Imajući u vidu da se prikupljanje podataka vrši isključivo u svrhe navedene  prethodno u tekstu, kao i u cilju ispunjavanja obaveza iz ovih Pravila i uslova  učestvovanja, dostavljanje nagrada i pripreme zaključivanja dogovora opisanog u  okviru ovog konkursa/kampanje, od učesnika će se zahtevati saglasnost za  korišćenje podataka od strane kompanije Reckitt Benckiser i agencije SmartPoint  Adria, kao i saglasnost za primanje koresponcencije od pomenutih pravnih  entiteta.</p>
						</li>
						<li>Učesnik/ca  potvrđuje da ima najmanje 18 godina, da je upisao/la tačne podatke (ime,  prezime, e-mail adresa i broj telefona), da je pročitao/la Pravila i uslove učestvovanja  i da je saglasan/na sa istim.</li>
						<li>Ovaj  konkurs/kampanja se može obustaviti ili odložiti u slučaju da postoje  objektivni razlozi za koje Organizator nije odgovoran, koje nije mogao da  predvidi, spreči, eliminiše niti izbegne, a koji značajno utiču na  implementiranje i realizaciju konkursa/kampanje.</li>
		
						</ol>
						<br/>
						<p>U  Beogradu,
						Novembar  2018,<br/> SmartPoint Adria, uz ovlašćenje kompanije Reckitt Benckiser</p>
						<br/><br/>
						
                    </Paper>
                </Grid>
                <Grid item xs>
                </Grid>
            </Grid>
            <Footer />	
			</Hoc>
		);
	}
};

const mapStateToProps = state => {
	return {
		curPath: state.my.currentPath,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onUpdatePath: (newpath) => dispatch (actions.changePath(newpath)),
	};
};


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Rules));