import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    loading:false,
    status:0
}

const messageStartSending = (state, action) => {
    return updateObject (state, {loading: true });
}

const messageStopLoading = (state, action) => {
    return updateObject (state, {loading: false });
}

const messageResult = (state, action ) => {
    let fr;
    if (action.result===true) {
        fr = 1;
    } else if (action.result===false) {
        fr = 2
    } else {
        fr = 0;
    }
    return updateObject (state, 
        {
            status : fr
        }
    )
}


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
		case actionTypes.MESSAGE_START_SEND: return messageStartSending(state, action);
        case actionTypes.MESSAGE_END_SEND: return messageStopLoading(state, action);
        case actionTypes.MESSAGE_RESULT: return messageResult(state, action);
        default:
            return state;
    }
};

export default reducer;