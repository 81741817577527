export const LANDINGPAGE_READY = 'LANDINGPAGE_READY';
export const CHANGE_MODAL = 'CHANGE_MODAL';
export const CHANGE_PANO = 'CHANGE_PANO';
export const SPINNER_STATE = 'SPINNER_STATE';
export const CHANGE_LANDINGPAGEPOSITION = 'CHANGE_LANDINGPAGEPOSITION';
export const CHANGE_SIDEDRAWER = 'CHANGE_SIDEDRAWER';
export const CHANGE_PATH = 'CHANGE_PATH';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const RESET_ERROR_STATE = 'RESET_ERROR_STATE';

export const GET_PROFILE_START = 'GET_PROFILE_START';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';
export const SET_PROFILE_RESULTS = 'SET_PROFILE_RESULTS';
export const GET_PROFILE_RESULTS = 'GET_PROFILE_RESULTS';
export const GET_ALL_RESULTS = 'GET_ALL_RESULTS';
export const SET_ALL_RESULTS = 'SET_ALL_RESULTS';
export const GET_WINNERS = 'GET_WINNERS';
export const SET_WINNERS = 'SET_WINNERS';
export const SET_PROFILE_AVATAR = 'SET_PROFILE_AVATAR';
export const DELETE_PROFILE_AVATAR = 'DELETE_PROFILE_AVATAR';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const SET_LOGOUT_WARNING = 'SET_LOGOUT_WARNING';
export const SET_LOGIN_WARNING = 'SET_LOGIN_WARNING';
export const SEND_VERIFICATION = 'SEND_VERIFICATION';

export const MESSAGE_START_SEND = 'MESSAGE_START_SEND';
export const MESSAGE_END_SEND = 'MESSAGE_END_SEND';
export const MESSAGE_RESULT = 'MESSAGE_RESULT';