import firebase from 'firebase/app';
import '@firebase/storage';
import '@firebase/database';
import '@firebase/auth';

const config = {
    apiKey: "AIzaSyAq4An7w67YNkU29-yH7hFUneHv3ZueiKI",
    authDomain: "durex-360.firebaseapp.com",
    databaseURL: "https://durex-360.firebaseio.com",
    projectId: "durex-360",
    storageBucket: "durex-360.appspot.com",
    messagingSenderId: "722697140763"
}
firebase.initializeApp(config);

const fire = firebase;
const storage = fire.storage();
const db = fire.database();
const fireauth = fire.auth();

export {
    firebase,
    fireauth,
    config,
    storage,
    db,
}