import React, { Component } from 'react';
import styles from './Dobitnici.module.css';

import HeaderTitle from '../../components/UI/HeaderTitle/HeaderTitle';
import Footer from '../Footer/Footer';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Hoc from '../../hoc/hoc';
import Spinner from '../../components/UI/LoadingSpinner'

import Moment from 'moment';

import { withRouter } from 'react-router-dom';

/* redux stuff*/
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

//const staticAvatar = '/img/avatar-blank.png';

class Dobitnici extends Component {

    componentDidMount = () => {
        this.props.onUpdatePath('/dobitnici');
        this.props.onGetWinners();
    }

    render () {

        let spin = null;
		if (this.props.spin) {
			spin = <Spinner/>
        }

        let winnerList = (<h2>Za sada nema dobitnika</h2>);
        
        if (this.props.winners.length>0) {
            winnerList = this.props.winners.map ( el => {
                const imglink = '/img/pano-thumb-0'+el["panoramanr"]+'.jpg';
				const escapedMessage = el['message'].split('\\n').map(function (item) {
					return (
						<span key={Math.random()}>
							{item}
							<br/>
						</span>
					)
                });
                const daytoshow = Moment(el['day']).format("DD.MM.");
                return (
                    <div className={styles.winnerHolder} key={Math.random()}>
                    <h3>Dobitnik za dan {daytoshow}</h3>
                    <div className={styles.winner}>
                        {/*<img src={el['avatar'] ? el['avatar'] : staticAvatar } alt="Avatar" />*/}
                        <div className={styles.flexColumn}>
                            <span><strong>Ime: </strong> {el['name']}</span>
                            <span style={{fontSize:'14px'}}><strong>Vreme opravdavanja: </strong>{el['time']}</span>
                        </div>
                        <div className={styles.opravdanje}>
                            <img src={imglink} alt=""/>
                            <span>{escapedMessage}</span>
                        </div>
                    </div>
                    </div>
                )                
            })
        }

        return (
            <Hoc>
            <HeaderTitle title="Dobitnici"/>
            <Grid container spacing={24}>
                <Grid item xs>
                </Grid>
                <Grid item sm={8} xs={10}>
                    <Paper className={styles.paper}>
                        <img src="/img/snow-roof-element-01.png" className={styles.snowTL1} alt=""/>
                        <img src="/img/snow-bottom-middle-01.png" className={styles.snowBM1} alt=""/>
                        <img src="/img/snow-bottom-middle-02.png" className={styles.snowBM2} alt=""/>
                        <img src="/img/snow-roof-element-02.png" className={styles.snowTR1} alt=""/>
                        <div className={styles.resultHolder}>
                            <div className={styles.results}>
                                {winnerList}
                            </div>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs>
                </Grid>
            </Grid>
            <div className={styles.SpinnerHolder}>
				{spin}
			</div>
            <Footer />
            </Hoc>
        );
    }
};

const mapStateToProps = state => {
	return {
		authToken: state.auth.token,
		userID: state.auth.userId,
		curPath: state.my.currentPath,
		spin: state.auth.loading,
		winners: state.auth.winnerList
	}
}

const mapDispatchToProps = dispatch => {
	return {
        onUpdatePath: (newpath) => dispatch (actions.changePath(newpath)),
        onGetWinners: () => dispatch (actions.getWinners()),
	}
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dobitnici));