import * as actionTypes from './actionTypes';
import axios from 'axios';
import axiosDurex from '../../axios-setup';
import {fireauth, storage, config} from '../../config/firebase';

//import storage from '../../config/firebase';
import Moment from 'moment';

const apiKey = config.apiKey;
//const dbUrl = fire.config.databaseURL;
//const storageBucket = config.storageBucket;

export const authStart = () => {
	return {
		type: actionTypes.AUTH_START
	};
};

export const authSuccess = (token, userId) => {
	return {
		type: actionTypes.AUTH_SUCCESS,
		idToken: token,
		userId: userId
	};
};

export const resetErrorState = () => {
	return {
		type: actionTypes.RESET_ERROR_STATE
	};
};

export const authFail = (error) => {
	//console.log('er: '+JSON.stringify(error));
	return {
		type:actionTypes.AUTH_FAIL,
		error: error
	};
};

export const auth = (email, password, typeOfForm, name, phone) => {
	return dispatch => {
		dispatch(authStart());
		let authData = {
			email: email,
			password: password,
			returnSecureToken: true,
			name: name,
			phone: phone
		}
		let url = '';

		if (typeOfForm==='login') {
			authData=null;
			authData = {
				email: email,
				password: password,
				returnSecureToken: true,
			};			
			url = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key='+apiKey;
			//url = 'https://us-central1-durex-360.cloudfunctions.net/auth';
		} else if (typeOfForm==='signup') {
			//url = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key='+apiKey;
			//url = 'http://localhost:5000/durex-360/us-central1/cu';
			url = 'https://us-central1-durex-360.cloudfunctions.net/cu';
		} else if (typeOfForm==='forgot') {
			url = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/getOobConfirmationCode?key='+apiKey;
			authData=null;
			authData = {
				email: email,
				requestType: 'PASSWORD_RESET'
			}
		}


		if (typeOfForm==='signup') {
			
			// USER REGISTRATION BRANCH

			var phonePost = { phone: phone, email: email };
			//const urlPhone = 'https://us-central1-durex-quiz-11996.cloudfunctions.net/writePh';
			//const urlPhone = 'http://localhost:5000/durex-360/us-central1/writePh';
			const urlPhone = 'https://us-central1-durex-360.cloudfunctions.net/writePh';

			// switch to firebase server functions
			axios.post(urlPhone, phonePost)
			.then((response) => {
				//console.log(response.data);
				if (response.data['message'] === 'CAN_PLAY') {
					axios.post(url, authData)
					.then(innerResponse => {
						//console.log('inner response data: ' + JSON.stringify(innerResponse.data));
						if (innerResponse.data.message==="USER_CREATED") {
							//console.log(innerResponse.data.message);
							dispatch(loginAndSendVerificationEmail(email,password,true));
							dispatch(loginWarning(true,'user_created'));
							return(true);
						}
					})
					.catch( (err) => {
						//console.log(err);
						dispatch(authFail(err));
					});

				} else if (response.data['message'] === 'CANT_PLAY_PHONE') {
					dispatch(authFail({message:'PHONE_TAKEN'}))
				} else if (response.data['message'] === 'CANT_PLAY_EMAIL') {
					dispatch(authFail({message:'EMAIL_EXISTS'}))
				}
				return true;
			})
			.catch( (err) => {
				console.warn(err);
			})

		} else if (typeOfForm==='login') {

			// USER LOGIN AND REST

			fireauth.signInWithEmailAndPassword(email,password)
			.then ( response => {
				//console.log('verified',response.user.emailVerified);
				//console.log('credential',response);

				if (response.user.emailVerified) {
					// user is verified - we log him in with old flow
					response.user.getIdTokenResult()
					.then( res => {
						//console.log('innerres: ' + JSON.stringify(res));
						var issueTs = res.claims.auth_time;
						var expTs = res.claims.exp;

						const expirationDate = new Date(expTs*1000);
						//console.log('issuets: ',issueTs);
						//console.log('expts: ',expTs);
						//console.log('expdate: ',expirationDate);
						var expiresIn = expTs-issueTs;
						localStorage.setItem('token', res.token);
						localStorage.setItem('expirationDate', expirationDate);
						localStorage.setItem('userId', res.claims.user_id);
	
						dispatch(authSuccess(res.token, res.claims.user_id));
						dispatch(checkAuthTimeout(expiresIn));
	
						const avatar = localStorage.getItem('avatar');
						//console.log('checking local storage: ' + avatar);
						if (avatar) {
							//console.log('local storage avatar found');
							dispatch(setProfileAvatar(avatar))
						} else {
							//console.log('no local storage avatar found');
							dispatch(getAvatarLinkFromFB(res.token, res.claims.user_id));
						}						
					})
					.catch( err => {
						console.log(err);
					})


				} else {
					/// email is not verified
					//console.log('email not verified and we are logged in', response.user)
					dispatch(loginWarning(true,'email_not_verified'));
				}
			})
			.catch( (err) => {
				//console.log(err);
				if (err.code==='auth/user-not-found') {
					dispatch(authFail({'message':'EMAIL_NOT_FOUND'}))
				} else if (err.code==='auth/wrong-password') {
					dispatch(authFail({'message':'INVALID_PASSWORD'}));
				}
			})
			
		} else if (typeOfForm==='forgot') {
			
			axios.post(url, authData)
			.then(response => {
				//console.log(response);
				if (response.data.kind === 'identitytoolkit#GetOobConfirmationCodeResponse') {
					dispatch(authFail({message:'EMAIL_RESET_SENT'}))
				}
			})
			.catch( (err) => {
				//console.log(err);
				dispatch(authFail(err.response.data.error));
			});
		}

	};
};


export const loginAndSendVerificationEmail = (email, password, firstTime) => {
	return dispatch => {
		dispatch(authStart());
		//console.log(email,password,firstTime);
		if (firstTime) {
			// used when we are registering user for the first time
			// it is default value that user won't have verified account
			// so no check is required
			// we first log in the newly created user

			fireauth.signInWithEmailAndPassword(email,password)
			.then ( response => {

				var user = fireauth.currentUser;

				user.sendEmailVerification()
				.then( res => {
					// Email sent.
					dispatch(loginWarning(true,'email_verification_sent'));
					dispatch(authFail({message:'EMAIL_VERIFY_SENT'}))
					return true;
				}).catch((error)=> {
					//console.log(error);
					return false;
				});
			})
			.catch(err => {
				//console.log('signin with email pass for sending verification email - failed: '+err)
			})

		} else {
			// we do this if user has already logged in but check returned that he is not verified
			// this event is activated on button inside login component

			var user = fireauth.currentUser;
			//console.log('inside login and verify - false: ', user);
			user.sendEmailVerification()
			.then( res => {
				// Email sent.
				dispatch(loginWarning(true,'email_verification_sent'));
				dispatch(authFail({message:'EMAIL_VERIFY_SENT'}))
				return true;
			}).catch((error)=> {
				//console.log(error);
				return false;
			});
		}
	}
}

export const getResults = (uid,tok) => {

	return dispatch => {
		dispatch(authStart());
		var userHighScores=[['Datum rezultata','Moje opravdanje']];
		//axiosDurex.get('/messages/'+uid+'.json?orderBy="confirmed"&equalTo=true')
		axiosDurex.get('/messages/'+uid+'.json?auth='+tok)
		.then ( response => {
			for (var key in response.data) {
				var dateStr = Moment(parseInt(key,10)).format("DD.MM.YYYY HH:mm");
				var message = response.data[key]['messageText'];
				var panorama = response.data[key]['type'];
				userHighScores.push([dateStr,message,panorama,key]);
			}
			userHighScores.sort(function(x, y){
				return x[3] - y[3];
			});
			dispatch(setResults(userHighScores));
		})
		.catch ( (err) => {
			//console.log(err);
		})
	}
}


export const getAllResults = (day) => {
	//console.log(day);
	return dispatch => {
		dispatch(startResultsGet());
		var resultObj=[];
		//axiosDurex.get('/resultsByDay/'+day+'.json')
		//axios.get('http://localhost:5000/durex-360/us-central1/gar?key='+apiKey+'&date='+day)
		const urlResults = 'https://us-central1-durex-360.cloudfunctions.net/gar';
		const data = {date:day};
		axios.post(urlResults,data)
		.then( res => {
			//console.log(res.data.length);
			for (var m in res.data) {
				//console.log(res.data[m]);
				var subData = {
					...res.data[m],
					avatar: '/img/avatar-blank.png'
				}
				resultObj[m] = subData;
			}
			dispatch(setAllResults(resultObj));
		})
		.catch( (err) => {
			console.log('error getting result by day: '+err);
		});
	}
}


export const setAllResults = (results) => {
	return {
		type: actionTypes.SET_ALL_RESULTS,
		results: results,
	}
}



export const startResultsGet = () => {
	return {
		type: actionTypes.GET_PROFILE_RESULTS,
	}
}


export const setResults = (results) => {
	return {
		type: actionTypes.SET_PROFILE_RESULTS,
		results: results,
	}
}


export const setWinners = (results) => {
	return {
		type: actionTypes.SET_WINNERS,
		winners: results,
	}
}

export const startGetWinners = () => {
	return {
		type: actionTypes.GET_WINNERS
	}
}


export const getWinners = () => {

	return dispatch => {
		dispatch(startGetWinners());
		var resultObj=[];
		axiosDurex.get('/dailyWinners.json')
		.then( res => {
			for (var m in res.data) {
				resultObj.push(res.data[m]);
			}
			//console.log(resultObj);
			dispatch(setWinners(resultObj));

		})
		.catch( (err) => {
			console.log('error getting winner list: '+err);
		});
	}

}


export const logout = () => {
	localStorage.removeItem('token');
	localStorage.removeItem('expirationDate');
	localStorage.removeItem('userId');
	return {
		type: actionTypes.AUTH_LOGOUT
	};
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
		}, expirationTime * 1000);
		
		if (expirationTime > 300) {
			setTimeout(()=> {
				dispatch(logoutWarning(true));
				// 5 minutes
			}, (expirationTime-300)*1000);
		} else {
			dispatch(logoutWarning(true));
		}
    };
};

export const authCheckState = () => {
    return dispatch => {
		const token = localStorage.getItem('token');
		//console.log(token);
        if (!token) {
            dispatch(logout());
        } else {
			const expirationDate = new Date(localStorage.getItem('expirationDate'));
			//console.log(expirationDate, new Date());
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
				const userId = localStorage.getItem('userId');
				//console.log(userId);
				dispatch(authSuccess(token, userId));
				var expTime = (expirationDate.getTime() - new Date().getTime()) / 1000;
				dispatch(checkAuthTimeout(expTime));

				// get avatar either from local storage or FB
				const avatar = localStorage.getItem('avatar');
				//console.log('checking local storage: ' + avatar);
				if (avatar) {
					//console.log('local storage avatar found');
					dispatch(setProfileAvatar(avatar))
				} else {
					//console.log('no local storage avatar found');
					dispatch(getAvatarLinkFromFB(token, userId));
				}
            }
        }
    };
};

export const logoutWarning = ( warning ) => {
	return {
		type: actionTypes.SET_LOGOUT_WARNING,
		warn: warning
	}
}

export const getProfileSuccess = (profile) => {
	return {
		type: actionTypes.GET_PROFILE_SUCCESS,
		profile: profile
	}
};

export const getProfileFail = (error) => {
	return {
		type: actionTypes.GET_PROFILE_FAIL,
		error: error
	};
};

export const getProfileStart = () => {
	return {
		type: actionTypes.GET_PROFILE_START
	}
}

export const getProfile = (authToken, userID) => {
	//console.log('getProfile',authToken);
	return dispatch => {
		dispatch(getProfileStart());
		axiosDurex.get('/users/'+userID+'.json?auth='+authToken)
			.then( res => {
				const profileData = [];
				for (let key in res.data ) {
					profileData[key] = res.data[key];
				}
				dispatch(getProfileSuccess(profileData));
			} )
			.catch ( err => {
				dispatch(getProfileFail(err));
			})
	}
}

export const setAvatar = (imgData,uid,authToken) => {
	//localStorage.setItem('avatar', imgData);
	return dispatch => {
		dispatch(authStart());
		var storageRef = storage.ref(uid).child('avatar.png');

		var metadata = {
			contentType: 'image/png'
		};

		var task = storageRef.putString(imgData, 'data_url', metadata);
		task.on('state_changed', function (snapshot) {

		}, function (error) {

		}, function () {
			task.snapshot.ref.getDownloadURL().then(function (downloadURL) {
				//console.log('dlurl',downloadURL);
				//dispatch(setProfileAvatar(imgData));
				localStorage.setItem('avatar', downloadURL); // set url localy 
				dispatch(setProfileAvatar(downloadURL)); // set it to store for this session
				dispatch(saveProfileAvatar(downloadURL,uid,authToken)); // save to fb profile
			})
		});
	}
}

export const removeAvatar = (uid,authToken) => {
	return dispatch => {
		dispatch(authStart());
		var storageRef = storage.ref(uid).child('avatar.png');
		storageRef.getDownloadURL()
		.then( url => {
			//console.log(url);
			/*var task = */storageRef.delete();
			dispatch(unsetProfileAvatar());
			dispatch(saveProfileAvatar(null,uid,authToken));
			return true;
		})
		.catch ( (err) => {
			console.log('ERROR: '+err);
			dispatch(unsetProfileAvatar());
			dispatch(saveProfileAvatar(null,uid,authToken));
		})
		localStorage.removeItem('avatar');
	}		
}

export const setProfileAvatar = (url) => {
	return {
		type: actionTypes.SET_PROFILE_AVATAR,
		url: url
	}
}

export const unsetProfileAvatar = () => {
	return {
		type: actionTypes.DELETE_PROFILE_AVATAR,
	}
}

export const saveProfileAvatar = (url,uid,token) => {
	// saving to users FB branch
	return dispatch => {
		axiosDurex.patch('/users/'+uid+'.json?auth='+token,{
			'avatar': url
		})
		.then(()=> {
			//console.log('avatar set');
		})
		.catch((err)=> {
			console.log(err);
		});

		axiosDurex.patch('/usersPublic/'+uid+'.json?auth='+token,{
			'avatar': url
		})
		.then(()=> {
			//console.log('avatar public set');
		})
		.catch((err)=> {
			console.log(err);
		});

	}

}

export const getAvatarLinkFromFB = (token,uid) => {
	return dispatch => {
		axiosDurex.get('/users/'+uid+'.json?auth='+token)
		.then(response => {
			//console.log('get url from firebase: '+ response.data.avatar);
			if (response.data.avatar===undefined) {
				return true;
			} else {
				dispatch(setProfileAvatar(response.data.avatar));
				return true;
				/*
				axios.get(response.data.avatar, {
					responseType: 'arraybuffer',
					crossDomain: true
    			}).then(response => {
					const bin = Buffer.from(response.data, 'binary').toString('base64');
					console.log(bin);

				})
				.catch((err) => {
					//console.log('error getting binary image data from url: '+err)
				})
				*/
			}
			
		})
		.catch((err)=> {
			//console.log('no avatar in database: ' + err);
		});
	}
}

export const deleteProfile = (token, uid, ph) => {
	return dispatch => {

		const url = 'https://us-central1-durex-360.cloudfunctions.net/deleteUserData';
		/*
		const deleteUrl = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/deleteAccount?key='+apiKey;
		const payload = {
			idToken: token
		}
		*/

		dispatch(authStart());
		//dispatch(removeAvatar(uid,token));

		// switch to firebase server functions
		axios.post(url, {
			uid:uid,
			tel:ph
		})
		.then((response) => {
			//console.log(response.data);
			if (response.data['message'] === 'USER_DATA_DELETED') {
				dispatch(logout());
				dispatch(doDeleteProfile());
			}
		})
		.catch((err)=> {
			console.log('ERROR_DELETING_USER_DATA:',err);
		});
	}
}

export const doDeleteProfile = () => {
	return {
		type: actionTypes.DELETE_PROFILE
	}	
}


export const loginWarning = (warning,warningType) => {
	//console.log('reporting from loginWarning action: ',warning,warningType);
	return {
		type: actionTypes.SET_LOGIN_WARNING,
		warning: warning,
		warningType: warningType
	}
}