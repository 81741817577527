import React, { Component } from 'react';
import styles from './LandingPage.module.css';

//import ScrollTrigger from 'react-scroll-trigger';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Snow from 'react-snow-effect';
//import Typography from '@material-ui/core/Typography';

import ReactGA from 'react-ga';

import Spinner from '../../components/UI/LoadingSpinner'
import Hoc from '../../hoc/hoc';
import Footer from '../Footer/Footer';

import { withRouter } from 'react-router-dom';

/* redux stuff*/
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

const mLinks = {
	'one':'',
	'two':'kako-da-ucestvujem',
	'three':'objasni-dedi',
	'four':'galerija-opravdanja',
	'five':'dobitnici',
	'six':'profil'
}

class LandingPage extends Component {

	doClick = (nm) => {
        this.props.history.push('/'+mLinks[nm]);
        
        ReactGA.event({
            category: 'Navigation',
            action: 'Clicked menu link on home page:' + mLinks[nm],
        });
	}

	componentWillMount () {
		this.props.spinnerChange(true);
	}

	componentDidMount() {
		this.props.onRdyLanding();
        this.props.spinnerChange(false);
	};


    render () {

        let spin = null;
		if (this.props.spin) {
			spin = <Spinner/>
        }

        return (
            <Hoc>
                <div className={styles.main}>
                    <Snow />
                    <img
                        src='./img/main-splash.jpg'
                        alt='Objasni dedi'
                        className={styles.logo}
                    />
                </div>
                <Hoc>
                    <div className={styles.prizes}>
                        <div className={styles.stickerHolder}>
                            <img 
                                src='./img/stiker.png'
                                alt='Deda Mraze, dozvoli da objasnim...'
                                className={styles.sticker}
                            />
                        </div>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <p>
                                    Jutro posle novogodišnje noći... <br/>
                                    Deda Mraz kasni, celu noć je proveo na mukama, pokušavajući da odluči ko je bio nevaljao, 
                                    a ko zaslužuje poklon.  <br/> <br/>
                                    Napokon, spušta se niz dimnjak. Ulazi u jednu od prostorija i ima šta da vidi!  <br/> <br/>
                                    <span className={styles.larger}>TRAGOVI NEVALJALOSTI SU SVUDA!</span>  <br/> <br/>
                                    Ako misliš da zaslužuješ poklon koji ti je namenio, objasni Dedi da nije tvoja krivica i da 
                                    nemaš nikakve veze sa onim što se izdešavalo prethodne noći! 
                                    <br/> <br/>
                                    Najkreativnija, najoriginalnija i najubedljivija opravdanja osvajaju nagrade i to:
                                </p>
                                <Grid container spacing={24}>
                                <Grid item xs={12} md={5} className={styles.prizeHolder}>
                                    <img
                                        src='./img/prize-01-lp-title.png'
                                        alt="Glavna nagrada"
                                        className={styles.prizeTitle}
                                    />
                                    <img
                                        src='./img/prize-01-lp-amsterdam.png'
                                        alt="Amsterdam"
                                        className={styles.prizeBody}
                                    />
                                    <img
                                        src='./img/prize-01-lp-seal.png'
                                        alt="1x Putovanje za dvoje u Amsterdam"
                                        className={styles.prizeSeal}
                                    />
                                </Grid>
                                <Grid item xs={"auto"} md={2} ></Grid>
                                <Grid item xs={12} md={5} className={styles.prizeHolder}>
                                <img
                                        src='./img/prize-02-lp-title.png'
                                        alt="Dnevna nagrada"
                                        className={styles.prizeTitle}
                                    />
                                    <img
                                        src='./img/prize-02-lp-paketic.png'
                                        alt="Paketić"
                                        className={styles.prizeBody}
                                    />
                                    <img
                                        src='./img/prize-02-lp-seal.png'
                                        alt="40x Novogodišnji paketić"
                                        className={styles.prizeSeal}
                                    />
                                </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </div>
                    <div className={styles.containerRegular}>
                        <img src="/img/snow-divider-element_01.png" alt="" className={styles.dividerTL} />
                        <img src="/img/snow-divider-element_02.png" alt="" className={styles.dividerTR} />
                        <img src="/img/snow-bottom-middle-01.png" alt="" className={styles.dividerBL} />
                        <img src="/img/snow-bottom-middle-02.png" alt="" className={styles.dividerBR} />
                        <Button aria-label="Kako da učestvujem?"
                            size="large" color="primary"
                            variant="contained"
                            onClick={ () => { this.doClick('two') } }
                            className={styles.xmasButton}
                        >
                            <strong>Kako da učestvujem?</strong>
                        </Button>
                        <Button 
                            aria-label="Objasni dedi"
                            size="large" color="primary"
                            variant="contained"
                            onClick={ () => { this.doClick('three') } }
                            className={styles.xmasButton}
                        >
                            <strong>OBJASNI DEDI</strong>
                        </Button>


                    </div>

                    <div className={styles.containerRegularDarker}>
                        <p>Za sva pitanja i nedoumice pišite nam na</p>
                        <a className={styles.emailLink} href="mailto:info@objasnidedi.rs">info@objasnidedi.rs</a>
                    </div>
                </Hoc>
                <Footer />
                <div className={styles.SpinnerHolder}>
				    {spin}
			    </div>                
            </Hoc>
        );
    }
};


const mapStateToProps = state => {
	return {
		rdy: state.my.landingPageReady,
		spin: state.auth.loading,
		curPath: state.my.currentPath,
	};
}

const mapDispatchToProps = dispatch => {
	return {
		onRdyLanding: () => dispatch( actions.landingpageReady() ),
		spinnerChange: (spin) => dispatch ( actions.spinnerState(spin) ),
		onUpdateLPPosition: (lpPos) => dispatch ( actions.changeLandingPagePosition(lpPos) ),
		onUpdatePath: (newpath) => dispatch (actions.changePath(newpath)),
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingPage));