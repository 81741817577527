import * as actionTypes from './actionTypes';
import axios from 'axios';
//import axiosDurex from '../../axios-setup';
//import {config} from '../../config/firebase';
//import axiosDurex from '../../axios-setup';

export const messageStartLoading = () => {
    return {
        type: actionTypes.MESSAGE_START_SEND
    }
}

export const messageStopLoading = () => {
    return {
        type: actionTypes.MESSAGE_END_SEND
    }
}

export const messageResult = (res) => {
    return {
        type: actionTypes.MESSAGE_RESULT,
        result: res
    }
}

export const sendMessage = (message, panotype, uid, ts) => {

    //console.log (message, panotype, uid, ts);
    const url = 'https://us-central1-durex-360.cloudfunctions.net/sm';//?key='+apiKey+'&message='+message+'&panotype='+panotype+'&ts='+ts;
    //const url = 'http://localhost:5000/durex-360/us-central1/sendMessageData';//?message='+message+'&panotype='+panotype+'&ts='+ts+"&uid="+uid;

    return dispatch => {
        dispatch(messageStartLoading());
        axios.post(url, {
            message: message,
            panotype: panotype,
            ts: ts,
            uid: uid
        })
        .then((response)=> {
            if (response.data === 'DATABASE_WRITE_OK') {
                dispatch(messageResult(true));
            } else if (response.data === 'DATABASE_WRITE_FAILED') {
                dispatch(messageResult(false));
            }
            dispatch(messageStopLoading());
            return true;
        })
        .catch((err)=> {
            console.log('greska pri upisu poruke u bazu',err);
            dispatch(messageResult(0));
            return false;
        })
    }

}