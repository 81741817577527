import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import Backdrop from '../../UI/Backdrop/Backdrop';
import Hoc from '../../../hoc/hoc';

import ReactGA from 'react-ga';

import * as actions from '../../../store/actions/index';

import { connect } from 'react-redux';

import classes from './SideDrawer.module.css';


const mLinks = {
	'one':'',
	'two':'kako-da-ucestvujem',
	'three':'objasni-dedi',
	'four':'galerija-opravdanja',
	'five':'dobitnici',
	'six':'profil'
}


class SideDrawer extends Component {
/*
  static propTypes = {
	  prop: PropTypes
  }
*/

  doClick = (nm) => {
		if (nm === "profile") {
			this.props.history.push('/profil');
			this.props.onUpdatePath('/profil');
		} else {
			this.props.history.push('/'+mLinks[nm]);
			this.props.onUpdatePath('/'+mLinks[nm]);
		}
        

    ReactGA.event({
      category: 'Navigation',
      action: 'Clicked menu link:' + mLinks[nm],
    });

    this.props.onUpdateSideDrawer(false);
    
	}

  render() {
    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (this.props.sideState) {
      attachedClasses = [classes.SideDrawer, classes.Open];
    }

    //const menulist = this.props.menuLinks.map ( (d) => <MenuItem key={d} onClick={ () => {this.doClick('one')}}>{d}</MenuItem> );

    return (
      <Hoc>
        <Backdrop show={this.props.sideState} clicked={() => this.props.onUpdateSideDrawer(false)} />
        <Paper className={attachedClasses.join(' ')}>
          <div style={{width:'100%',textAlign:'center'}} >
            <img className={classes.Logo} src="/img/durex-logo-header-large.png" alt="" />
          </div>
          <MenuList className={classes.SideMenu}>
            { /*menulist */}

            <MenuItem
            onClick={ () => { this.doClick('one') } }
            >
            {this.props.menuLinks[0]}
            </MenuItem>
            <MenuItem
            onClick={ () => { this.doClick('two') } }
            >
            {this.props.menuLinks[1]}
            </MenuItem>
            <MenuItem
            onClick={ () => { this.doClick('three') } }
            >
            {this.props.menuLinks[2]}
            </MenuItem>
            <MenuItem
            onClick={ () => { this.doClick('four') } }
            >
            {this.props.menuLinks[3]}
            </MenuItem>
            <MenuItem
            onClick={ () => { this.doClick('five') } }
            >
            {this.props.menuLinks[4]}
            </MenuItem>
            <MenuItem
            //onClick={ () => { this.doClick('six') } }
            onClick={ () =>
							{
								if (this.props.isAuthenticated) {
									//this.props.onUpdateModal(true,'profile');
									this.doClick('profile');
								} else {
                  this.props.onUpdateModal(true,'login');
                  this.props.onUpdateSideDrawer(false);
								}
							} 
						}
            >
            {this.props.isAuthenticated ? this.props.menuLinks[5] : 'Prijava' }
            </MenuItem>
          </MenuList>
        </Paper>
      </Hoc>
      )
  }
}


const mapStateToProps = state => {
	return {
    isAuthenticated: state.auth.token !== null,
		sideState: state.my.showSideDrawer,
	};
}

const mapDispatchToProps = dispatch => {
	return {
    onUpdateSideDrawer: (sideState) => dispatch ( actions.changeSidedrawer(sideState) ),
    onUpdateLPPosition: (lpPos) => dispatch ( actions.changeLandingPagePosition(lpPos) ),
    onUpdateModal: (modState, modWhat) => dispatch( actions.changeModal(modState, modWhat) ),
    onUpdatePath: (newpath) => dispatch (actions.changePath(newpath)),
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideDrawer));