import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	landingPageReady: false,
	showModal: false,
    showInModal:'',
    showPanorama: false,
    showInPanorama:'',
    panoramaNumber:0,
    landingPagePosition: 0,
	showSideDrawer: false,
	spinnerState:true,
	currentPath:'/',
};

const lpReady = (state, action) => {
    return updateObject ( state, {
        landingPageReady: true
    })
}

const modalChange = ( state, action ) => {
    return updateObject ( state, {
        showModal:action.modalState,
        showInModal:action.showInModal
    })
}

const panoramaChange = ( state, action ) => {
    let panoNr = parseInt(action.showInPanorama.slice(-1));
    if (isNaN(panoNr)) {
        panoNr=0;
    }
    return updateObject ( state, {
        showPanorama:action.panoramaState,
        showInPanorama:action.showInPanorama,
        panoramaNumber:panoNr
    })
}

const spinnerState = ( state, action ) => {
    return updateObject (state, {
        spinnerState: action.spinner
    })
}

const sideDrawerChange = ( state, action ) => {
    return updateObject ( state, {
        showSideDrawer:action.sideDrawerChange
    })
}

const pathChange = (state, action ) => {
    return updateObject (state, {
        currentPath: action.path
    })
}

const pagePosition = (state, action ) => {
    return updateObject (state, {
        landingPagePosition:action.LPPosition,
    })
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
		case actionTypes.LANDINGPAGE_READY: return lpReady(state, action);
        case actionTypes.CHANGE_MODAL: return modalChange(state, action);
        case actionTypes.CHANGE_PANO: return panoramaChange(state, action);
		case actionTypes.SPINNER_STATE: return spinnerState(state, action);
        case actionTypes.CHANGE_SIDEDRAWER: return sideDrawerChange(state, action);
        case actionTypes.CHANGE_PATH: return pathChange(state, action);
        case actionTypes.CHANGE_LANDINGPAGEPOSITION: return pagePosition(state, action);
        default:
            return state;
    }
};

export default reducer;