import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';

import Hoc from '../../hoc/hoc';

import ReactGA from 'react-ga';

import classes from './Login.module.css';

class Login extends Component {

	state = {
		email:'',
		password:'',
		typeOfForm:'login',

		name:'',
		repeatPassword:'',
		tel:'',
		check1:false,
		check2:false,
		check3:false

	}

    componentDidMount() {
        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password || this.state.password !== this.state.repeatPassword) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isPasswordMatch2', (value) => {
			if (value !== this.state.repeatPassword && this.state.repeatPassword!=='') {
				return false;
			}
            return true;
        });
        // custom rule will have name 'isMobilePhone'
        ValidatorForm.addValidationRule('isMobilePhone', (value) => {
			var patt = /^(06\d)\d{6,9}$/i;
			return patt.test(value);
		});
        // custom rule for name
        ValidatorForm.addValidationRule('isAlphaSpace', (value) => {
			var patt = /^[a-zA-Z' ']{5,}$/i;
			return patt.test(value);
		});
		
    }

	setErrorMessage = (message) => {
		var newState = {
			...this.state,
			errorText: message
		}
		this.setState(newState);
		this.startErrorFadeOut();
	}

	startErrorFadeOut = () => {
		this.setTimeout(function () {
			this.props.onResetError();
		},2000);
	}

	handleChange = (event) => {
		const {name,value} = event.target;
		let newState = {
			...this.state,
			[name]:value
		}
		this.setState(newState);
	};

	handleCheckbox = (event) => {
		const {name} = event.target;
		let newState = {
			...this.state,
			[name]: event.target.checked
		}
		this.setState(newState);
	}

    handleSubmit = (event) => {
		event.preventDefault();
		if (this.state.typeOfForm==='login') {
			ReactGA.event({
				category: 'Authentication Action',
				action: 'Login',
			});

			this.props.onAuth(this.state.email, this.state.password, this.state.typeOfForm, null, null);
		} else if (this.state.typeOfForm==='signup') {

			ReactGA.event({
				category: 'Authentication Action',
				action: 'SignUp',
			});
		
			this.props.onAuth(this.state.email, this.state.password, this.state.typeOfForm, this.state.name, this.state.tel);
		} else if (this.state.typeOfForm==='forgot') {

			ReactGA.event({
				category: 'Authentication Action',
				action: 'Password forgot',
			});

			this.props.onAuth(this.state.email, null, 'forgot', null, null);
		} else if (this.state.typeOfForm==='emailNotVerified') {
			this.props.onSendVerificationEmail(this.state.email,this.state.password);
			
			ReactGA.event({
				category: 'Authentication Action',
				action: 'Send Email Validation'
			})
		}
	};

	switchAuthModeHandler = (type) => {
		let newState = {
			...this.state,
			check1:false,
			check2:false,
			check3:false,
			typeOfForm: type,
		}
		this.setState(newState);
	};

	handleErrorShow = () => {
		clearTimeout(this.clearErrorTimeout);
		this.clearErrorTimeout = setTimeout( () => {
			this.props.onResetError();
		}, 3000);
	};

    componentWillUnmount() {
		clearTimeout(this.clearErrorTimeout);
	}

	componentWillReceiveProps = (newProps) => {
		//console.log('login - comp will receive: ', newProps.loginWarning, this.props.loginWarning, newProps.loginWarningType);
		if (newProps.loginWarning && newProps.loginWarning!==this.props.loginWarning && newProps.loginWarningType==='user_created') {
			var newState = {
				...this.state,
				typeOfForm:'signupDone'
			}
			this.setState(newState);
		} 
		else if (newProps.loginWarning && newProps.loginWarning!==this.props.loginWarning && newProps.loginWarningType==='email_not_verified') {
			var newState2 = {
				...this.state,
				typeOfForm:'emailNotVerified'
			}
			this.setState(newState2);
		}
	}

	render () {
		let errorClass = [classes.errorWindow, classes.errorWindowHide];
		let errorText = ' ';
		if ( this.props.error !== null ) {
			errorClass = [classes.errorWindow];
			//console.log(this.props.error.message);
			this.handleErrorShow();
			switch (this.props.error.message) {
				case 'EMAIL_NOT_FOUND':
					errorText = 'E-mail nije pronađen! Kreirajte novi nalog.';
					break;
				case 'INVALID_PASSWORD':
					errorText = 'Lozinka je netačna! Pokušajte ponovo.';
					break;
				case 'TOO_MANY_ATTEMPTS_TRY_LATER':
					errorText = 'Previše uzastopnih grešaka! Pokušajte kasnije.';
					break;
				case 'EMAIL_EXISTS':
					errorText = 'Uneseni email je već registrovan.';
					break;
				case 'PHONE_TAKEN':
					errorText = 'Uneseni telefon je već registrovan.';
					break;					
				case 'EMAIL_RESET_SENT':
					errorText = 'E-mail za resetovanje lozinke je poslat!';
					errorClass = [classes.errorWindow, classes.infoWindow];
					break;
				case 'EMAIL_VERIFY_SENT':
					errorText = 'E-mail za verifikaciju naloga je poslat!';
					errorClass = [classes.errorWindow, classes.infoWindow];
					break;

				default:
					errorText = ' ';
			}
		} else {
			errorText= '&nbsp;';
		}

		let switchText = '';
		let titleText = '';

		if (this.state.typeOfForm === 'signup') {
			switchText = 'Prijavite se na svoj nalog';
			titleText = 'Registrujte se';
		} else if (this.state.typeOfForm === 'login') {
			switchText = 'Registrujte se';
			titleText = 'Prijavite se';
		} else if (this.state.typeOfForm === 'forgot') {
			switchText = 'Prijavite se na svoj nalog';
			titleText = 'Zaboravljena lozinka';
		} else if (this.state.typeOfForm === 'signupDone') {
			switchText = 'Prijavite se na svoj nalog';
			titleText = 'Registracija uspešna';
		} else if (this.state.typeOfForm === 'emailNotVerified') {
			switchText = 'Prijavite se na svoj nalog';
			titleText = 'Verifikacija e-mail adrese';
		}

		let disableSubmit = false;
		if (this.state.typeOfForm==='signup') {
			//console.log(this.state.check1,this.state.check2,this.state.check3);
			if (!this.state.check1 || !this.state.check2 || !this.state.check3) {
				disableSubmit = true;
			}
		}


		return (
			<div className={classes.loginWindow}>
				<div className={classes.loginTitle}>
					<Typography 
						align='center'
						gutterBottom 
						variant='title'>
							{titleText}
					</Typography>
				</div>
				<ValidatorForm
					ref="form"
					onSubmit={this.handleSubmit}
					onError={errors => console.log(errors)}
					className={classes.form}
				>
				{
					(this.state.typeOfForm==='signup')
					? (
					<TextValidator
						className={classes.textField}
						margin="normal"
						autoComplete="off"
						label="Ime i prezime"
						name="name"
						value={this.state.name}
						onChange={this.handleChange}
						validators={['required', 'minStringLength:6', 'isAlphaSpace']}
						errorMessages={['Ovo polje je obavezno', 'Vrednost mora biti veća od 6 karaktera', 'Uneseni su nedozvoljeni karakteri']}
					/>
					)
					: null
				}
				{
					(this.state.typeOfForm !== 'signupDone' && this.state.typeOfForm !== 'emailNotVerified')
					? (
						<TextValidator
						className={classes.textField}
						autoFocus
						margin="normal"
						autoComplete="off"
						label="E-mail adresa"
						name="email"
						value={this.state.email}
						onChange={this.handleChange}
						validators={['required', 'isEmail']}
						errorMessages={['Ovo polje je obavezno', 'E-mail nije validan']}
					/>
					)
					: null
				}
				{
					(this.state.typeOfForm==='signup')
					? (
					<TextValidator
						className={classes.textField}
						margin="normal"
						autoComplete="off"
						label="Broj telefona"
						name="tel"
						value={this.state.tel}
						onChange={this.handleChange}
						validators={['required', 'isMobilePhone']}
						errorMessages={['Ovo polje je obavezno', 'Upišite broj mobilnog telefona u formi 06XXXXXXXX']}
					/>
					)
					: null
				}
				{
					(this.state.typeOfForm==='login' || this.state.typeOfForm==='signup')
					? (
					<TextValidator
						autoComplete="new-password"
						id="password-input"
						name="password"
						label="Lozinka"
						className={classes.textField}
						type="password"
						margin="normal"
						onChange={this.handleChange}

						value={this.state.password}
						validators={['required','minStringLength:6','isPasswordMatch2']}
						errorMessages={['Ovo polje je obavezno','Dužina lozinke mora biti najmanje 6 karaktera','Lozinke se ne podudaraju']}
					/>
					)
					: null
				}
				{
					(this.state.typeOfForm==='signup')
					? (
					<TextValidator
						id="password-input2"
						name="repeatPassword"
						label="Potvrda lozinke"
						className={classes.textField}
						type="password"
						margin="normal"
						onChange={this.handleChange}

						value={this.state.repeatPassword}
						validators={['required','minStringLength:6','isPasswordMatch']}
						errorMessages={['Ovo polje je obavezno','Dužina lozinke mora biti najmanje 6 karaktera', 'Lozinke se ne podudaraju']}
					/>
					)
					: null
				}

				{
					(this.state.typeOfForm==='signup')
					? (
					<Hoc>
					<FormControlLabel
						className={classes.CheckBoxLabel}
              			control={
							<Checkbox
								checked={this.state.checked1}
								onChange={this.handleCheckbox}
								value="check1"
								id="checkbox-1"
								name="check1"
								label="Potvrda 1"
								className={classes.checkBox}
							/>
						  }
						  label="Potvrđujem da imam više od 18 godina, da sam pročitao/la Pravila i uslove učestvovanja, kao i da sam saglasan/na sa istim."
					/>
					<FormControlLabel 
						className={classes.CheckBoxLabel}
              			control={
							<Checkbox
								checked={this.state.checked2}
								onChange={this.handleCheckbox}
								value="check2"
								id="checkbox-2"
								name="check2"
								label="Potvrda 2"
								className={classes.checkBox}
							/>
						  }
						  label="Potvrđujem da me Reckitt Benckiser i SmartPoint Adria mogu kontaktirati radi ostvarivanja prava na nagradu, do 15. januara 2019. "
					/>
					<FormControlLabel
						className={classes.CheckBoxLabel}
              			control={
							<Checkbox
								checked={this.state.checked3}
								onChange={this.handleCheckbox}
								value="check3"
								id="checkbox-3"
								name="check3"
								label="Potvrda 3"
								className={classes.checkBox}
							/>
						  }
						  label="Potvrđujem da Reckitt Benckiser i SmartPoint Adria mogu koristiti moje podatke isključivo u cilju pristupa, korišćenja i učestvovanja u konkursu „Objasni Dedi“, kao i za potrebe identifikacije i slanja nagrada, do 15. januara 2019. godine."
					/>
					</Hoc>
					)
					: null
				}


				{
					(this.state.typeOfForm === 'signupDone')
					? (<p style={{textAlign:'center'}}><br/>Uspešno ste se registrovali. <br/> Na e-mail koji ste naveli pri registraciji,<br/>poslali smo link za proveru.<br/>
						<br/> Klikom na taj link vaš e-mail će biti potvrđen i moći ćete da se ulogujete i koristite aplikaciju.</p>
					)
					:null
				}

				{
					(this.state.typeOfForm === 'emailNotVerified')
					? (<Hoc><br/><p style={{textAlign:'center'}}>E-mail sa kojim ste se ulogovali nije <strong>potvrđen</strong>!<br/>
						<br/>Ukoliko niste dobili e-mail sa linkom za validaciju,<br/> možete ga poslati ponovo klikom na dugme.</p>
						</Hoc>
					)
					:null
				}

					<div className={errorClass.join(' ')}>
						{errorText}
					</div>

				{
					(this.state.typeOfForm !== 'signupDone')
					? (<Button variant="outlined" className={classes.button} type='submit'
						disabled={disableSubmit}
					>
						Potvrdite
					</Button>
					)
					: null
				}
				</ValidatorForm>



				<div className={classes.buttonSwitchHolder}>
					<Button 
						color="primary" 
						className={classes.buttonSwitch} 
						onClick={ () => {
							if (this.state.typeOfForm==='login') {
								this.switchAuthModeHandler('signup');
							} else if (this.state.typeOfForm==='signup') {
								this.switchAuthModeHandler('login');
							} else if (this.state.typeOfForm==='signupDone' || this.state.typeOfForm==='emailNotVerified') {
								this.switchAuthModeHandler('login');
								this.props.onSetLoginWarning(false,null);
							} else {
								this.switchAuthModeHandler('login');
							}
						} }
					>
						{switchText}
					</Button>
				{
					(this.state.typeOfForm==='login')
					? (
					<Button 
						color="secondary" 
						className={classes.buttonSwitch} 
						onClick={ () => {
							this.switchAuthModeHandler('forgot');
						} }
					>
						Zaboravljena lozinka
					</Button>
					)
					: null
				}
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		loading: state.auth.loading,
		error: state.auth.error,
		isAuthenticated: state.auth.token !== null,
		loginWarning: state.auth.loginWarning,
		loginWarningType: state.auth.loginWarningType,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onSendVerificationEmail: (email, password) => dispatch(actions.loginAndSendVerificationEmail(email,password,false)),
		onAuth: (email, password, isLogin, name, phone) => dispatch(actions.auth(email,password,isLogin,name,phone)),
		onResetError: () => dispatch(actions.resetErrorState()),
		onSetLoginWarning: (war, wartype) => dispatch(actions.loginWarning(war,wartype)),
	};
};

export default connect(mapStateToProps,mapDispatchToProps)(Login);