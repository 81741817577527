import React from 'react';
import Button from '@material-ui/core/Button'
import ArrowUp from '@material-ui/icons/ArrowUpward'

import classes from './BackToTop.module.css'

const backToTop = (props) => (
	<Button 
		data-pos={props.position}
		className={props.position > 0 ? classes.BackToTop + ' ' + classes.show : classes.BackToTop + ' ' + classes.hide}
		variant="fab"
		color="primary"
		aria-label="Back to top"
		onClick={props.returnToTop}
		>
	  	<ArrowUp/>
	</Button>

);

export default backToTop;