import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';

import thunk from 'redux-thunk';
import myreducer from './store/reducers/reducer';
import authReducer from './store/reducers/auth';
import commReducer from './store/reducers/communication';

import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware, ConnectedRouter } from 'connected-react-router'

import ReactGA from 'react-ga';
ReactGA.initialize('UA-48643604-8');

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//const composeEnhancers = compose;

const history = createBrowserHistory();

const historyListener = (location, action) => {
	//console.log(location,action);
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname);
}
history.listen(historyListener);
historyListener(window.location);


const rootReducer = combineReducers({

	my: myreducer,
	auth: authReducer,
	comm: commReducer,
})

const store = createStore(
	connectRouter(history)(rootReducer),
	composeEnhancers(
	applyMiddleware(
		routerMiddleware(history),
		thunk
	)
	)
);


const app = (
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<App />
		</ConnectedRouter>
	</Provider>
);

ReactDOM.render(app, document.getElementById('root'));
registerServiceWorker()
