import React from 'react';
import styles from './HeaderTitle.module.css';
import Snow from 'react-snow-effect';

const HeaderTitle = (props) => (
    <div className={styles.headerTitle}>
        <Snow />
        <h1>{props.title}</h1>
    </div>
);

export default HeaderTitle; 