import * as actionTypes from './actionTypes';

export const landingpageReady = () => {
	return {
		type: actionTypes.LANDINGPAGE_READY
	}
};

export const spinnerState = ( spinnerState ) => {
	return {
		type: actionTypes.SPINNER_STATE,
		spinner:spinnerState,
	}
};

export const changeModal = (modState, modWhat) => {
	return {
		type: actionTypes.CHANGE_MODAL,
		modalState:modState, 
		showInModal:modWhat
	}
}

export const changePano = (panoState, panoWhat) => {
	return {
		type: actionTypes.CHANGE_PANO,
		panoramaState:panoState, 
		showInPanorama:panoWhat
	}
}

export const changeSidedrawer = (sideState) => {
	return {
		type: actionTypes.CHANGE_SIDEDRAWER,
		sideDrawerChange: sideState
	}
}

export const changePath = (newpath) => {
	return {
		type: actionTypes.CHANGE_PATH,
		path:newpath
	}
}

export const changeLandingPagePosition = (lpos) => {
	return {
		type: actionTypes.CHANGE_LANDINGPAGEPOSITION,
		LPPosition: lpos
	}
}