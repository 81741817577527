import React, { Component } from 'react';
import styles from './Toolbar.module.css';
import Hoc from '../../../hoc/hoc';

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import LockIcon from '@material-ui/icons/Lock'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import HamburgerMenu from '@material-ui/icons/Menu';

import ReactGA from 'react-ga';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import * as actions from '../../../store/actions/index';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


const mLinks = {
	'one':'',
	'two':'kako-da-ucestvujem',
	'three':'objasni-dedi',
	'four':'galerija-opravdanja',
	'five':'dobitnici',
	'six':'profil'
}


class toolbar extends Component {

	state = {
		anchorEl: null
	}

	doClick = (nm) => {
		if (nm === "profile") {
			this.props.history.push('/profil');
			this.props.onUpdatePath('/profil');
		} else {
			this.props.history.push('/'+mLinks[nm]);
			this.props.onUpdatePath('/'+mLinks[nm]);
		}
        
        ReactGA.event({
            category: 'Navigation',
            action: 'Clicked menu link:' + mLinks[nm],
        });

	}

	// local state user menu
	handleUserMenuOpen = event => {
		this.setState({anchorEl: event.currentTarget});
	}

	handleUserMenuClose = () => {
		this.setState({anchorEl: null});
	}

    render () {

		let ico;
		ico = <HamburgerMenu />;

		let profileIcon;
		let profileMenu;

		if (this.props.isAuthenticated) {
			if (this.props.avatar) {
				profileIcon = <img src={this.props.avatar} className={styles.avatarImage} alt=""/>
			} else {
				profileIcon = <AccountCircle />;
			}

			profileMenu = (
				<Menu
					id="user-menu"
					anchorEl={this.state.anchorEl}
					open={Boolean(this.state.anchorEl)}
					onClose={this.handleUserMenuClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right'
					}}
					getContentAnchorEl={null}
				>
					<MenuItem onClick={() => {
						//this.props.onUpdateModal(true,'profile');
						this.doClick('profile');
						this.handleUserMenuClose();
						}}>
						Profil
					</MenuItem>
					<MenuItem onClick={() => {
						this.props.onLogout();
						this.handleUserMenuClose();
						}}>
						Odjava
					</MenuItem>
				</Menu>
			)
		} else {
			profileIcon = <LockIcon />;
			profileMenu = null;
		}            


        return (
            <Hoc>
			<AppBar position='relative' className={styles.mainAppBar} id="staticAppBar">
				<Toolbar disableGutters className={styles.mainAppBar}>
				<div className={styles.onlyMobile}>
					<IconButton
						className={styles.openMenuButton}
						variant="fab" 
						aria-label="More"

						aria-haspopup="true"
						onClick={() => this.props.onUpdateSideDrawer(true)}
					>
						{ico}
					</IconButton>
				</div>

				<div className={styles.menuHolder + ' ' + styles.onlyDesktop + ' ' + styles.menuHolderLeft} >
				  <Button 
						className={this.props.curPath==='/' ? styles.menuButtonHighlighted : styles.menubutton} 
						tabIndex="1"
						onClick={ () => { this.doClick('one') } }
					>{this.props.menuLinks[0]}</Button>
				  <Button 
					  className={this.props.curPath==='/kako-da-ucestvujem' ? styles.menuButtonHighlighted : styles.menubutton} 
					  tabIndex="2"
						onClick={ () => { /*this.doClick('quiz')*/this.doClick('two') } }>
						{this.props.menuLinks[1]}
						</Button>
					  <Button 
					  	className={this.props.curPath==='/objasni-dedi' ? styles.menuButtonHighlighted : styles.menubutton} 
					  	tabIndex="3"
						onClick={ () => { this.doClick('three') } }
					>
					{this.props.menuLinks[2]}
					</Button>
				</div>
				<div className={styles.menuHolder + ' ' + styles.onlyDesktop}
				>
				  <img
					src='./img/durex-logo-header-large.png'
					alt='Durex Love Sex'
					className={styles.logo}
				  />
				</div>
				<div className={styles.menuHolder + ' ' + styles.onlyDesktop + ' ' + styles.menuHolderRight} >
					<Button 
						className={this.props.curPath==='/galerija-opravdanja' ? styles.menuButtonHighlighted : styles.menubutton} 
						tabIndex="4"
						onClick={ () => { this.doClick('four') } }
					>
						{this.props.menuLinks[3]}
					</Button>
					<Button 
						className={this.props.curPath==='/dobitnici' ? styles.menuButtonHighlighted : styles.menubutton} 
						tabIndex="5"
						onClick={ () => { this.doClick('five') } }
					>
						{this.props.menuLinks[4]}
					</Button>
					<Button 
						className={this.props.curPath==="/profil" ? styles.menuButtonHighlighted : styles.menubutton}
						tabIndex="6"
						onClick={ () =>
							{
								if (this.props.isAuthenticated) {
									//this.props.onUpdateModal(true,'profile');
									this.doClick('profile');
								} else {
									this.props.onUpdateModal(true,'login');
								}
							} 
						}
					>
					{this.props.isAuthenticated ? this.props.menuLinks[5] : 'Prijava' }
					</Button>
				</div>
				<Hoc>
					<Tooltip id="tooltip-icon2" 
						title={this.props.isAuthenticated ? "Profil" : "Login"}
						placement="left"
					>
						<IconButton
							aria-owns={this.state.anchorEl ? 'user-menu' : null}
							aria-haspopup="true"
							className={styles.loginButton}
							name={this.props.isAuthenticated ? "profile" : "signin"}
							aria-label={this.props.isAuthenticated ? 'Profil' : 'Prijava'}
							color='inherit'
							rel='noopener'
							onClick={(e) =>
								{
									if (this.props.isAuthenticated) {
										this.handleUserMenuOpen(e);
									} else {
										this.props.onUpdateModal(true,'login');
									}
								}
							}
						>
							{profileIcon}
						</IconButton>
					</Tooltip>
					{profileMenu}
				</Hoc>
				</Toolbar>
			</AppBar>			
        	</Hoc>
        );
    }
};


const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.token !== null,
		curPath: state.my.currentPath,
		avatar: state.auth.avatarData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onUpdateSideDrawer: (sideState) => dispatch ( actions.changeSidedrawer(sideState) ),
		onUpdateModal: (modState, modWhat) => dispatch( actions.changeModal(modState, modWhat) ),
		onLogout: () => dispatch (actions.logout() ),
		onUpdatePath: (newpath) => dispatch (actions.changePath(newpath)),
	}
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(toolbar));