import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';

import ReactGA from 'react-ga';

import { withRouter } from 'react-router-dom';

import classes from './DeleteConfirmation.module.css';

class DeleteConfirmation extends Component {

	state = {
		delete: ''
	}

    componentDidMount() {
        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('deleteCheck', (value) => {
            if (value !== 'OBRISATI') {
                return false;
            }
            return true;
        });
    }

	handleChange = (event) => {
		const {name,value} = event.target;
		let newState = {
			...this.state,
			[name]:value
		}
		this.setState(newState);
	};	

    handleSubmit = (event) => {
		event.preventDefault();

		ReactGA.event({
			category: 'Profile Action',
			action: 'Deleted account',
		});

		this.props.onDeleteProfile(this.props.authToken, this.props.userID, this.props.profileArray['tel']);
		this.props.onUpdateModal(false,null);
		this.props.history.push('/');
		this.props.onUpdatePath('/');
	};


	render () {

		return (

			<div className={classes.deleteWindow}>
				<div className={classes.deleteTitle}>
					<Typography 
						align='center'
						gutterBottom 
						variant='title'>
							Brisanje profila
					</Typography>
				</div>
				<p>Ako ste sigurni da želite da obrišete profil sa sajta i uklonite sve vaše podatke, uključujući i rezultate:<br/><br/>u tekstualno polje upišite velikim slovima <strong>OBRISATI</strong> i pritisnite dugme za brisanje.</p>
				<ValidatorForm
					ref="form"
					onSubmit={this.handleSubmit}
					onError={errors => console.log(errors)}
					className={classes.form}
				>
					<TextValidator
						className={classes.textField}
						margin="normal"
						autoComplete="off"
						label="Upišite 'OBRISATI'"
						name="delete"
						value={this.state.delete}
						onChange={this.handleChange}
						validators={['required', 'deleteCheck']}
						errorMessages={['Ovo polje je obavezno', 'Morate upisati reč "OBRISATI"']}
					/>

					<Button variant="contained" color="secondary" className={classes.button}
							type='submit'
						>
							Obriši profil
						<DeleteIcon className={classes.rightIcon} />
					</Button>
				</ValidatorForm>
			</div>
		);
	}
};


const mapStateToProps = state => {
	return {
		authToken: state.auth.token,
		userID: state.auth.userId,
		profileArray: state.auth.profile,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onDeleteProfile: (tok,uid,ph) => dispatch(actions.deleteProfile(tok,uid,ph)),
		onUpdatePath: (newpath) => dispatch (actions.changePath(newpath)),
		onUpdateModal: (modState, modWhat) => dispatch( actions.changeModal(modState, modWhat) ),
	};
};


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(DeleteConfirmation));