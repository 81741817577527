import React, { Component } from 'react';
import styles from './Kako.module.css';

import HeaderTitle from '../../components/UI/HeaderTitle/HeaderTitle';
import Footer from '../Footer/Footer';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Hoc from '../../hoc/hoc';


import { withRouter } from 'react-router-dom';

/* redux stuff*/
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';


class Kako extends Component {

    componentDidMount = () => {
        this.props.onUpdatePath('/kako-da-ucestvujem');
        window.scrollTo(0, 0);
    }


    render () {
        return (
            <Hoc>
            <HeaderTitle title="Kako da učestvujem?"/>
            <Grid container spacing={24}>
                <Grid item xs>
                </Grid>
                <Grid item sm={8} xs={10}>
                    <Paper className={styles.paper}>
                        <h2>REGISTRUJTE SE</h2>
                        <p>
                            Pre učešća u konkursu, potrebno je da se registrujete i na taj način kreirate svoj nalog. Svaki sledeći put kada budete želeli da učestvujete, biće potrebno da se prijavite koristeći samo e-mail adresu i lozinku.
                        </p>
                        <hr/>
                        <h2>INFORMIŠITE SE</h2>
                        <p>
                            Naša najtoplija preporuka je da pre učešća u konkursu pročitate “Pravila i uslove učestvovanja”  u kojima ćete naći detaljno objašnjenje o tome kako se učestvuje, ko može da se prijavi, koji su kriterijumi za osvajanje nagrada, kojom se dinamikom dodeljuju nagrade i mnoge druge korisne informacije.
                        </p>
                        <hr/>
                        <h2>POŠALJITE NAM SVOJE OPRAVDANJE</h2>
                        <p>
                        Izaberite jednu od 3 ponuđene prostorije u kojima se evidentno dešavalo nešto nevaljalo prethodne noći. Vaš zadatak je da, na osnovu slike, Deda Mrazu objasnite šta se tu zapravo desilo. Pokušajte da ga ubedite da ste vi bili dobri i da zaslužujete novogodišnji poklon koji je pripremio za vas. 
                        </p>
                        <hr/>
                        <h2>PRATITE REZULTATE</h2>
                        <p>
                        Poruke svih učesnika možete pronaći u GALERIJI OPRAVDANJA. U okviru sekcije DOBITNICI nalaziće se imena i prezimena dobitnika dnevne nagrade, kao i glavne nagrade na kraju takmičenja. Ukoliko ste jedan od dobitnika, očekujte e-mail sa obaveštenjem o osvojenoj nagradi, na koji ćete moći da nam pošaljete podatke za ostvarivanje prava na istu.
                        </p>
                        <hr/>
                        <h2>ZABAVITE SE</h2>
                        <p>
                        Pustite mašti na volju i zabavite se, ne samo pišući, već i čitajući izgovore drugih učesnika! Nagrade osvajaju kreativna, originalna i duhovita opravdanja i zato jedva čekamo da pročitamo i vaše!
                        </p>

                        
                    </Paper>
                </Grid>
                <Grid item xs>
                </Grid>
            </Grid>
            <Footer />
            </Hoc>
        );
    }
};

const mapDispatchToProps = dispatch => {
	return {
        onUpdatePath: (newpath) => dispatch (actions.changePath(newpath)),
	}
}


export default withRouter(connect(null, mapDispatchToProps)(Kako));