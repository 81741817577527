import React, { Component } from 'react';
import classes from './Footer.module.css';

import { withRouter } from 'react-router-dom';

import ReactGA from 'react-ga';

/* redux stuff*/
import { connect } from 'react-redux';
//import * as actions from '../../store/actions/index';


class Footer extends Component {

	doClick2 = function () {
        this.props.history.push('/pravila');
	}

	render () {
        return (
            <div className={classes.container}>
			<div className={classes.footer}>
					<div className={classes.footerLeft}>
						<p>&copy; Reckitt Benckiser Serbia</p>
						<p>Kampanja traje od 19. novembra do 28. decembra 2018.</p>
					</div>
					<div className={classes.footerRight}>
						<p><span
						className={classes.footerLink} 
						onClick={ () => { this.doClick2() } }
						>Pravila i uslovi učestvovanja</span></p>
						<p>Pratite nas na <img src="/img/fb-icon.png" alt=""/> 
							<a href="https://www.facebook.com/Durex.Srbija/" target="_blank"  rel="noopener noreferrer"
							onClick= { () => {
								ReactGA.event({
									category: 'Navigation',
									action: 'Clicked Facebook Link - footer'
								});
                            }}

						>Durex.Srbija</a></p>
					</div>
			</div>
            </div>
        );
    }
};

const mapStateToProps = state => {
	return {
		/*
		authToken: state.auth.token,
		userID: state.auth.userId,
		isAuthenticated: state.auth.token !== null,
		curPath: state.my.currentPath,
		*/
	}
}

const mapDispatchToProps = dispatch => {
	return {
		//onUpdatePath: (newpath) => dispatch (actions.changePath(newpath)),
		//onGetProfile: (tok,id) => dispatch(actions.getProfile(tok,id)),
	};
};



export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Footer));