import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    token: null,
    userId: null,
    error: null,
    loading: false,
    profile: [],
    avatarData:null,
    profileResults: [],
    allResults: [],
    logoutWarning: false,
    winnerList:[],
    
    loginWarning:false,
    loginWarningType:null
};

const authStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const resetErrorState = (state, action) => {
    return updateObject( state, { error: null } );
}

const authSuccess = (state, action) => {
    return updateObject( state, { 
        token: action.idToken,
        userId: action.userId,
        error: null,
        loading: false
     } );
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { token: null, userId: null, logoutWarning:false});
};

const getProfileStart = (state, action) => {
    return updateObject(state,{ loading: true });
}

const getProfileSuccess = (state, action) => {
    return updateObject( state, {
        profile: action.profile,
        loading: false,
        avatarData: action.profile['avatar']
    });
}
const getProfileFail = ( state, action ) => {
    return updateObject(state, {loading:false});
}

const getProfileResults = (state, action) => {
	return updateObject ( state, { 
		loading: true
	});
}

const setProfileResults = ( state, action ) => {

    return updateObject ( state, {
        profileResults: action.results,
        loading: false
    })
}

const getAllResults = (state, action) => {
	return updateObject ( state, { 
		loading: true
	});
}

const setAllResults = ( state, action ) => {
    var sres = [];
    for (var n in action.results) {
        sres.push(action.results[n]);
    }

    return updateObject ( state, {
        allResults: action.results,
        loading: false
    })
}





const setProfileAvatar = ( state, action ) => {
    return updateObject ( state, {
        avatarData:action.url,
        loading: false
    })
}

const deleteProfileAvatar = ( state, action ) => {
    return updateObject ( state, {
        profile : {
            ...state.profile,
            avatar: null
        },
        loading: false,
        avatarData: null
    })
}

const deleteProfile = ( state, action ) => {
    return updateObject ( state, {
        ...initialState
    })
}

const setLogoutWarning = (state, action ) => {
    return updateObject ( state, {
        logoutWarning: action.warn
    })
}

const getWinners = (state, action) => {
    return updateObject ( state, { 
		loading: true
	}); 
}

const setWinners = (state, action ) => {
    return updateObject ( state, {
        winnerList: action.winners,
        loading: false
    })
}

const setLoginWarning = (state, action) => {
    return updateObject ( state, {
        loginWarningType: action.warningType,
        loginWarning: action.warning,
        loading:false
    })
}

const sendVerification = (state, action) => {
    return state;
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.RESET_ERROR_STATE: return resetErrorState(state,action);
        case actionTypes.GET_PROFILE_START: return getProfileStart(state,action);
        case actionTypes.GET_PROFILE_FAIL: return getProfileFail(state,action);
        case actionTypes.GET_PROFILE_SUCCESS: return getProfileSuccess(state,action);
        case actionTypes.SET_PROFILE_RESULTS: return setProfileResults(state,action);
        case actionTypes.GET_PROFILE_RESULTS: return getProfileResults(state,action);
        case actionTypes.GET_ALL_RESULTS: return getAllResults(state,action);
        case actionTypes.SET_ALL_RESULTS: return setAllResults(state,action);
        case actionTypes.GET_WINNERS: return getWinners(state,action);
        case actionTypes.SET_WINNERS: return setWinners(state,action);
        case actionTypes.SET_PROFILE_AVATAR: return setProfileAvatar(state,action);
        case actionTypes.DELETE_PROFILE_AVATAR: return deleteProfileAvatar(state,action);
        case actionTypes.DELETE_PROFILE: return deleteProfile(state,action);
        case actionTypes.SET_LOGOUT_WARNING: return setLogoutWarning(state,action);
        case actionTypes.SET_LOGIN_WARNING: return setLoginWarning(state,action);
        case actionTypes.SEND_VERIFICATION: return sendVerification(state,action);
        default:
            return state;
    }
};

export default reducer;