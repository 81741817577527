import React from 'react';
import styles from './PanoramaHolder.module.css';

import Button from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Clear';
import ExplainAddIcon from '@material-ui/icons/PlaylistAdd';

import Hoc from '../../../hoc/hoc';
import Backdrop from '../Backdrop/Backdrop';

const PanoramaHolder = (props) => (
    <Hoc>
        <Backdrop show={props.show} clicked={props.modalClosed} />
        <div className={styles.panoContainer}
            style={{
                transform: props.show ? 'scale(1)' : 'scale(0)',
                opacity: props.show ? '1' : '0',
                visibility: props.show ? 'visible': 'hidden'
            }}
        >
				{props.children}
				<div className={styles.closeButton}>
                    <Button aria-label="Close"
                        variant="fab"
						onClick={props.modalClosed}
					>
						<CloseIcon />
					</Button>
				</div>
                <div className={styles.objasniButton}>
                    <Button aria-label="Objasni"
                        variant="fab"
                        onClick={props.activateExplanationModal}
                    >
                        <ExplainAddIcon />
                    </Button>
                </div>
        </div>
    </Hoc>
);

export default PanoramaHolder;