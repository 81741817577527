import React, { Component } from 'react';
import styles from './Objasni.module.css';

import HeaderTitle from '../../components/UI/HeaderTitle/HeaderTitle';
import Footer from '../Footer/Footer';

import Grid from '@material-ui/core/Grid';
//import Paper from '@material-ui/core/Paper';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Hoc from '../../hoc/hoc';

import { withRouter } from 'react-router-dom';

/* redux stuff*/
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

class Objasni extends Component {

    componentDidMount = () => {
        this.props.onUpdatePath('/objasni-dedi');
    }

    doClick = (nr) => {
        if (nr===1) {
            this.props.onUpdatePano(true,'panorama1')
        } else if (nr===2) {
            this.props.onUpdatePano(true,'panorama2')
        } else if (nr===3) {
            this.props.onUpdatePano(true,'panorama3')
        }
        
    }

    render () {
        let loginButton = null;
        if (!this.props.isAuthenticated) {
            loginButton = (
                <Button variant="contained" size="small" color="primary"
                    onClick={(e) =>
                        {
                            this.props.onUpdateModal(true,'login');
                        }
                    }                            

                >
                    Prijavite se
                </Button>
            );
        }

        return (
            <Hoc>
            <HeaderTitle title="Objasni dedi"/>

            <Grid container spacing={24}>
                <Grid item xs>
                </Grid>
                <Grid item container sm={8} xs={10}>
                    <div className={styles.paper}>
                        <Typography variant="h4" gutterBottom>
                            Izaberi sobu i napiši ubedljivo opravdanje za Deda Mraza!
                        </Typography>
                        <Typography variant="h5">
                            Ubedi ga da zatečeno stanje nema veze sa tobom i da ipak zaslužuješ poklon.
                        </Typography>
                    </div>

                    <Grid item lg={4} md={12}>

                        <Card id="card1" className={styles.card}>
                            <img src="/img/snow-bottom-right-01.png" className={styles.snowBR1} alt="" />
                            <img src="/img/snow-bottom-left-01.png" className={styles.snowBL1} alt=""/>
                            <img src="/img/snow-roof-element-01.png" className={styles.snowTL1} alt=""/>
                            <CardActionArea 
                                onClick={(e) =>
                                    {
                                        if (this.props.isAuthenticated) {
                                            this.doClick(1);
                                        } else {
                                            this.props.onUpdateModal(true,'login');
                                        }
                                    }
                                }                            
                            >
                            <CardMedia
                                component="img"
                                alt="Panorama 01"
                                className={styles.media}
                                height="200"
                                image="/img/pano-thumb-01.jpg"
                                title="Panorama 01"
                            />
                            <CardContent className={styles.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2" align="center">
                                Dnevna soba
                                </Typography>
                                <Typography component="p">
                                Mesto gde sve počinje, tamo gde se dragi gosti opuste i raskomote. Neki možda i previše? Deda Mraz stiže na lokaciju u ranu zoru i... ima šta da vidi!
                                </Typography>
                            </CardContent>
                            </CardActionArea>
                            <CardActions className={styles.cardActions}>
                                <Button variant="contained" size="small" color="primary" disabled={this.props.isAuthenticated ? false : true}
                                    onClick={(e) =>
                                        {
                                            this.doClick(1);
                                        }
                                    }
                                >
                                    Objasni dedi
                                </Button>
                                {loginButton}
                            </CardActions>
                        </Card>

                    </Grid>
                    <Grid item lg={4} md={12}>
                        <Card id="card2" className={styles.card}>
                            <img src="/img/snow-bottom-middle-01.png" className={styles.snowBM1} alt=""/>
                            <img src="/img/snow-roof-element-02.png" className={styles.snowTR1} alt=""/>
                            <CardActionArea 
                                onClick={(e) =>
                                    {
                                        if (this.props.isAuthenticated) {
                                            this.doClick(2);
                                        } else {
                                            this.props.onUpdateModal(true,'login');
                                        }
                                    }
                                }                            
                            >
                            <CardMedia
                                component="img"
                                alt="Panorama 02"
                                className={styles.media}
                                height="200"
                                image="/img/pano-thumb-02.jpg"
                                title="Panorama 02"
                            />
                            <CardContent className={styles.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2" align="center">
                                Spavaća soba
                                </Typography>
                                <Typography component="p">
                                Epicentar dešavanja, mesto gde kulminacija večeri ima više značenja! Čik ubedite Deda Mraza da ništa od ovoga nije vaša krivica!
                                </Typography>
                            </CardContent>
                            </CardActionArea>
                            <CardActions className={styles.cardActions}>
                                <Button variant="contained" size="small" color="primary" disabled={this.props.isAuthenticated ? false : true}
                                    onClick={(e) =>
                                        {
                                            this.doClick(2);
                                        }
                                    }
                                >
                                    Objasni dedi
                                </Button>
                                {loginButton}
                            </CardActions>
                        </Card>


                    </Grid>
                    <Grid item lg={4} md={12}>


                        <Card id="card3" className={styles.card}>
                            <img src="/img/snow-bottom-middle-02.png" className={styles.snowBM2} alt=""/>
                            <img src="/img/snow-roof-element-03.png" className={styles.snowTM1} alt=""/>
                            <CardActionArea 
                                onClick={(e) =>
                                    {
                                        if (this.props.isAuthenticated) {
                                            this.doClick(3);
                                        } else {
                                            this.props.onUpdateModal(true,'login');
                                        }
                                    }
                                }                            
                            >
                            <CardMedia
                                component="img"
                                alt="Panorama 03"
                                className={styles.media}
                                height="200"
                                image="/img/pano-thumb-03.jpg"
                                title="Panorama 03"
                            />
                            <CardContent className={styles.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2" align="center">
                                Kuhinja
                                </Typography>
                                <Typography component="p">
                                Mesto gde jedna vrsta gladi prerasta u drugu, ako nas razumete. Teško da ćete se opravdati kod Deda Mraza za ovo što će zateći!
                                </Typography>
                            </CardContent>
                            </CardActionArea>
                            <CardActions className={styles.cardActions}>
                                <Button variant="contained" size="small" color="primary" disabled={this.props.isAuthenticated ? false : true}
                                    onClick={(e) =>
                                        {
                                            this.doClick(3);
                                        }
                                    }
                                >
                                    Objasni dedi
                                </Button>
                                {loginButton}
                            </CardActions>
                        </Card>


                    </Grid>
                </Grid>
                <Grid item xs>
                </Grid>
            </Grid>
            <div style={{display:"block",height:"50px"}}>
            </div>
            <Footer/>
            </Hoc>            
        );
    }
};

const mapStateToProps = state => {
	return {
        isAuthenticated: state.auth.token !== null
	};
}

const mapDispatchToProps = dispatch => {
	return {
        onUpdateModal: (modState, modWhat) => dispatch( actions.changeModal(modState, modWhat) ),
        onUpdatePano: (panoState, panoWhat) => dispatch( actions.changePano(panoState, panoWhat) ),
        onUpdatePath: (newpath) => dispatch (actions.changePath(newpath)),
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Objasni));