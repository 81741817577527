import React, { Component } from 'react';
import styles from './GalerijaOpravdanja.module.css';

import HeaderTitle from '../../components/UI/HeaderTitle/HeaderTitle';
import Footer from '../Footer/Footer';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Hoc from '../../hoc/hoc';
import DayPicker from 'react-day-picker';

import Moment from 'moment';

import Spinner from '../../components/UI/LoadingSpinner'
import { withRouter } from 'react-router-dom';

/* redux stuff*/
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';


//const staticAvatar = '/img/avatar-blank.png';

const MONTHS = [
	'Januar',
	'Februar',
	'Mart',
	'April',
	'Maj',
	'Jun',
	'Jul',
	'Avgust',
	'Septembar',
	'Oktobar',
	'Novembar',
	'Decembar',
  ];
  const WEEKDAYS_LONG = [
	'Nedelja',
	'Ponedeljak',
	'Utorak',
	'Sreda',
	'Četvrtak',
	'Petak',
	'Subota'
];

const WEEKDAYS_SHORT = ['Ned', 'Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub'];

class GalerijaOpravdanja extends Component {

	state = {
		selectedDay: new Date(),
		currentList: null,

		currentIndex: null,

		stringDay: null,

        hoverRange: undefined,
		selectedDays: [],
	}

    componentDidMount = () => {
        this.props.onUpdatePath('/galerija-opravdanja');
		this.handleDayClick(new Date());
    }


	handleDayClick = (day, modifiers = {}) => {
		var dt = new Date(day);
		//var timeOffset = dt.getTimezoneOffset();
		//console.log(timeOffset);
		var midnight = String(dt.getFullYear())+String(dt.getMonth()+1)+String(dt.getDate());
		var readableString = (String(dt.getDate()))+". "+MONTHS[(dt.getMonth())]+" "+String(dt.getFullYear())
		//console.log(midnight);
		if (modifiers.disabled) {
			return;
		}
		this.setState({
			selectedDay: modifiers.selected ? undefined : day,
			stringDay: readableString,
			currentList: null,
			currentIndex: null
        });
		this.props.onGetAllResults(midnight);

    }

    
    render () {

		let spin = null;
		if (this.props.spin) {
			spin = <Spinner/>
        }
        
        //let endDaysDate = new Date(2018, 11, 28);
        let endDaysDate = new Date();

        let dailyWinnerList = (<h2>Za izabrani dan nema opravdanja</h2>);
        const dailyHeader = this.props.results.length > 0 ? (<h2>Opravdanja za dan: {Moment(this.state.selectedDay).format("DD.MM.")} </h2>) : null;

        if (this.props.results.length>0) {
            dailyWinnerList = this.props.results.map( el => {
                const imglink = '/img/pano-thumb-0'+el["type"]+'.jpg';
				const escapedMessage = el['message'].split('\\n').map(function (item) {
					return (
						<span key={Math.random()}>
							{item}
							<br/>
						</span>
					)
				});
                return <div className={styles.winner} key={el['times']}>
                        {/*<img src={el['avatar'] ? el['avatar'] : staticAvatar } alt="Avatar" />*/}
                        <div className={styles.flexColumn}>
                            <span><strong>Ime: </strong> {el['name']}</span>
                            <span style={{fontSize:'14px'}}><strong>Vreme opravdavanja: </strong>{Moment(parseInt(el['times'],10)).format("HH:mm")}</span>
                        </div>
                        <div className={styles.opravdanje}>
                            <img src={imglink} alt=""/>
                            <span>{escapedMessage}</span>
                        </div>
                    </div>
            })
        }


        return (
            <Hoc>
            <HeaderTitle title="Galerija opravdanja"/>
            <Grid container spacing={32}>
                <Grid item lg={1} xs={12}>
                </Grid>
                <Grid item lg={3} xs={12}>
                    <div className={styles.dayPickerHolder}>
                        <DayPicker 
                            className={styles.DayPicker}
                            onDayClick={this.handleDayClick}
                            locale="sr"
                            months={MONTHS}
                            weekdaysLong={WEEKDAYS_LONG}
                            weekdaysShort={WEEKDAYS_SHORT}
                            firstDayOfWeek={1}
                            selectedDays={this.state.selectedDay}
                            showOutsideDays={false} 
                            month={new Date()}
                            fromMonth={new Date(2018, 10)}
                            toMonth={new Date(2018, 11)}
                            disabledDays={[
                                {
                                    after: endDaysDate,
                                    before: new Date(2018, 10, 19),
                                },
                                ]}
                        />
                    </div>

                </Grid>
                <Grid item lg={"auto"} xs={1}></Grid>
                <Grid item lg={6} xs={10}>
                    <Paper className={styles.paper}>
                        <img src="/img/snow-bottom-left-01.png" className={styles.snowBL1} alt=""/>
                        <img src="/img/snow-roof-element-02.png" className={styles.snowTR1} alt=""/>
                        <img src="/img/snow-roof-element-03.png" className={styles.snowTM1} alt="" />
                        <img src="/img/snow-bottom-right-01.png" className={styles.snowBR1} alt="" />
                        <div className={styles.resultHolder}>
                                <div className={styles.results}>
                                    {dailyHeader}
                                    {dailyWinnerList}
                                </div>
                        </div>
                        
                    </Paper>
                </Grid>
                <Grid item lg={1} xs={12}></Grid>
            </Grid>
            <div className={styles.SpinnerHolder}>
				{spin}
			</div>
            <Footer />
            </Hoc>
        );
    }
};

const mapStateToProps = state => {
	return {
		authToken: state.auth.token,
		userID: state.auth.userId,
		curPath: state.my.currentPath,
		spin: state.auth.loading,
		results: state.auth.allResults,
		avatarUrl: state.auth.avatarData,
	}
}


const mapDispatchToProps = dispatch => {
	return {
        onUpdatePath: (newpath) => dispatch (actions.changePath(newpath)),
        onGetAllResults: (day) => dispatch( actions.getAllResults(day) ),
	}
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GalerijaOpravdanja));