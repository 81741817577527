import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
//import AddPhoto from '@material-ui/icons/AddAPhoto';

//import ScrollTrigger from 'react-scroll-trigger';

import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Logout from '@material-ui/icons/ExitToApp';
//import Save from '@material-ui/icons/Save';

import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
//import Avatar from 'react-avatar-edit'

import ReactGA from 'react-ga';

import { withRouter } from 'react-router-dom';

import Spinner from '../../components/UI/LoadingSpinner'
import Hoc from '../../hoc/hoc';

import classes from './Profile.module.css';

class Profile extends Component {
/*
	constructor(props) {
		super(props)
		this.state = {
			preview: null
		}
		this.onCrop = this.onCrop.bind(this)
		this.onClose = this.onClose.bind(this)
	}
*/

	state = {
		preview: null,
		authToken:null,
		profileArr:null,
	};

	/*
	shouldComponentUpdate(nextProps,nextState) {
		if (nextProps.authToken!==null) {
			return true;
		}
		console.log(nextProps,nextState);
		return false;
	}
	*/

	componentWillReceiveProps (nextProps) {
		//console.log(nextProps.authToken, this.state.authToken, nextProps.isAuthenticated);
		if (nextProps.authToken !== this.state.authToken && nextProps.isAuthenticated) {
			this.setState({
				...this.state,
				authToken:nextProps.authToken
			});
			this.props.onGetProfile(nextProps.authToken,nextProps.userID);
			this.props.onGetResults(nextProps.userID, nextProps.authToken);
		} else if (!nextProps.isAuthenticated) {
			this.props.history.push('/');
			this.props.onUpdatePath('/');
		}
	}


	onEnterViewport = (nr) => {
		this.props.onUpdateLPPosition(nr);
	}


	componentDidMount () {
		//console.log('didmount', this.props.isAuthenticated, this.props.curPath);
		window.scrollTo(0, 0);
		this.props.onUpdatePath('/profil');

		if (this.props.isAuthenticated) {
			this.props.onGetProfile(this.props.authToken,this.props.userID);
			this.props.onGetResults(this.props.userID, this.props.authToken);
		}
	}

	onClose = () => {
		this.setState({
			...this.state,
			preview: null
		})
	}

	onCrop = (preview) => {
		this.setState({
			...this.state,
			preview:preview
		})
	}

	activateDeleteConfirmation = () => {

		ReactGA.event({
			category: 'Profile Action',
			action: 'Delete initialization',
		});

		this.props.onUpdateModal(true,'deleteConfirm');
	}


	logoutUser = () => {
		this.props.onLogout();
	}

	saveAvatarIcon = () => {
		this.props.onUploadAvatar(this.state.preview,this.props.userID,this.props.authToken,this.props.profileArray);
	}

	deleteAvatarIcon = () => {
		this.props.onDeleteAvatar(this.props.userID,this.props.authToken,this.props.profileArray);
	}

	render () {

		let spin = null;
		if (this.props.spin) {
			spin = <Spinner/>
		}
		let mygames;
		//console.log(this.props.results);
		if (this.props.results!==undefined) {
			mygames = this.props.results.map((el) => {
				const imglink = '/img/pano-thumb-0'+el[2]+'.jpg';
				const escapedMessage = el[1].split('\\n').map(function (item) {
					return (
						<span key={Math.random()}>
							{item}
							<br/>
						</span>
					)
				});
				return (<div className={classes.resultHolder} key={el}>
							<div className={classes.singleResultTime}>
								<span>
								{el[0]}
								</span>
							</div>
							<div className={classes.singleResultImage}>
								<img src={imglink} alt=""/>
							</div>
							<div className={classes.singleResultText}>
								{escapedMessage}
							</div>
						</div>
						)
			});
		}

		return (
			<div className={classes.main}>
			<div className={classes.profileWindow} location={this.props.curPath} ref={ (div) => {this.zero = div}}>
				<div className={classes.profileTitle}>
					<Typography
						align='center'
						variant='title'
					>
						Profil
					</Typography>
				</div>
				<hr/>
				<div className={classes.profileData}>
					{/*
					<Paper 
						elevation={1}
						className={classes.avatar}
					>
						<div className={classes.avatarHolder} >
							{ this.props.avatarUrl 
							? (
								<Hoc>
									<img src={this.props.avatarUrl} alt="Avatar"/>
									<IconButton 
									color="primary" 
									aria-label="Obriši profilnu sliku"
									title="Obriši profilnu sliku"
									onClick={this.deleteAvatarIcon}
									>
										<DeleteIcon />
									</IconButton>
								</Hoc>
							)
							: (
								<Hoc>
									<div style={{display:'block',height:'4vh',width:'1px'}}></div>
									<AddPhoto className={classes.photoIcon}/>
									<Avatar
										width={160}
										height={160}
										label="Izaberite sliku"
										closeIconColor="#333"
										onCrop={this.onCrop}
										onClose={this.onClose}
										src={this.state.src}
										class={classes.avatarTool}
									/>
									<img src={this.state.preview} alt="Preview"/>
									<IconButton 
										color="primary" 
										aria-label="Snimi profilnu sliku"
										disabled={this.state.preview?false:true}
										onClick={this.saveAvatarIcon}
									>
										<Save />
									</IconButton>
								</Hoc>
							)
							}
						</div>
					</Paper>
						*/}

					<div className={classes.info}>
						<div className={classes.infoFields}>
							<Typography 
								align='left'
								variant='body2'
								className={classes.profileInputLabel}>
								Ime i prezime:
							</Typography>
							<Typography 
								align='left'
								gutterBottom
								variant='body1'
								className={classes.profileInput}>
								{this.props.profileArray['ime']}
							</Typography>
							<Typography 
								align='left'
								variant='body2'
								className={classes.profileInputLabel}>
								E-mail:
							</Typography>
							<Typography 
								align='left'
								gutterBottom
								variant='body1'
								className={classes.profileInput}>
								{this.props.profileArray['eml']}
							</Typography>
							<Typography 
								align='left'
								variant='body2'
								className={classes.profileInputLabel}>
								Telefon:
							</Typography>
							<Typography 
								align='left'
								gutterBottom
								variant='body1'
								className={classes.profileInput}>
								{this.props.profileArray['tel']}
							</Typography>
						</div>
						<div className={classes.deleteButtonHolder}>
							<Button variant="contained" color="primary" className={classes.button}
								onClick={this.logoutUser}
							>
								Odjava&nbsp;
								<Logout className={classes.rightIcon} />
							</Button>

							<Button variant="contained" color="secondary" className={classes.button}
								onClick={this.activateDeleteConfirmation}
							>
								Obriši profil&nbsp;
								<DeleteIcon className={classes.rightIcon} />
							</Button>							
						</div>
					</div>
				</div>
			
				{ this.props.results!==undefined && this.props.results.length>1
				? (
					<Hoc>
						<div className={classes.separator}></div>
						<div className={classes.profileTitle} >
							<Typography
								align='center'
								variant='title'
							>
								Moja opravdanja
							</Typography>
						</div>
						<hr/>
						<Paper className={classes.profileResultHolder} elevation={2}>
							{mygames}
						</Paper>
					</Hoc>
				)
				: (
					<Hoc>
						<hr/>
					</Hoc>
				)
				}

			</div>
			<div className={classes.SpinnerHolder}>
				{spin}
			</div>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		authToken: state.auth.token,
		userID: state.auth.userId,
		profileArray: state.auth.profile,
		isAuthenticated: state.auth.token !== null,
		curPath: state.my.currentPath,
		spin: state.auth.loading,
		results: state.auth.profileResults,
		avatarUrl: state.auth.avatarData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onLogout: () => dispatch (actions.logout() ),
		onGetProfile: (tok,id) => dispatch(actions.getProfile(tok,id)),
		onUpdateModal: (modState, modWhat) => dispatch( actions.changeModal(modState, modWhat) ),
		onUpdatePath: (newpath) => dispatch (actions.changePath(newpath)),
		onGetResults: (uid, tok) => dispatch(actions.getResults(uid, tok)),
		onUploadAvatar: (imgData,uid,tok,parr) => dispatch(actions.setAvatar(imgData,uid,tok,parr)),
		onDeleteAvatar: (uid,tok,parr) => dispatch(actions.removeAvatar(uid,tok,parr)),
		onUpdateLPPosition: (lpPos) => dispatch ( actions.changeLandingPagePosition(lpPos) ),
	};
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Profile));