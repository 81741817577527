import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExitToApp from '@material-ui/icons/ExitToApp';

import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import classes from './LogoutWarning.module.css';

class DeleteConfirmation extends Component {

    doLogout = (event) => {
		this.props.onLogout();
		this.props.onUpdateModal(false,null);
		this.props.onLogoutWarning(false);
		this.props.history.push('/');
		this.props.onUpdatePath('/');
	};

	componentWillUnmount () {
		this.props.onLogoutWarning(false);
	}


	render () {

		return (

			<div className={classes.deleteWindow}>
				<div className={classes.deleteTitle}>
					<Typography 
						align='center'
						gutterBottom 
						variant='title'>
							Isticanje sesije
					</Typography>
				</div>
				<p>Vaša sesija uskoro ističe.<br/>Molimo vas da se ulogujete ponovo.</p>
				<Button variant="contained" color="secondary" className={classes.button}
					onClick={this.doLogout}
					>
					Izlogujte se
					<ExitToApp className={classes.rightIcon} />
				</Button>
			</div>
		);
	}
};


const mapStateToProps = state => {
	return {
		authToken: state.auth.token,
		userID: state.auth.userId,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onLogoutWarning: (show) => dispatch(actions.logoutWarning(show)),
		onUpdateModal: (modState, modWhat) => dispatch( actions.changeModal(modState, modWhat) ),
		onLogout: () => dispatch (actions.logout() ),
		onUpdatePath: (newpath) => dispatch (actions.changePath(newpath)),
	};
};


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(DeleteConfirmation));