import React, { Component } from 'react';
import Hoc from '../../hoc/hoc';
import Toolbar from '../Navigation/Toolbar/Toolbar'
import SideDrawer from '../Navigation/SideDrawer/SideDrawer'
import Modal from '../UI/Modal/Modal';
import PanoramaHolder from '../UI/PanoramaHolder/PanoramaHolder';
import {Pannellum} from '360-react-pannellum';

import Spinner from '../../components/UI/LoadingSpinner'
import BackToTop from '../UI/BackToTop/BackToTop';

import {withRouter} from 'react-router-dom';
import Login from '../../containers/Login/Login';
import DeleteConfirmation from '../../containers/DeleteConfirmation/DeleteConfirmation';
import LogoutWarning from '../../containers/LogoutWarning/LogoutWarning';
import MessageSend from '../../containers/MessageSend/MessageSend';

//import Profile from '../../containers/Profile/Profile'

import styles from './Layout.module.css';

/* redux stuff*/
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';


class Layout extends Component {

	state = {
		menuLinks : [
			'Početna',
			'Kako da učestvujem?',
			'Objasni dedi',
			'Galerija opravdanja',
			'Dobitnici',
			'Profil'
		],
	};

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
            this.cancelPanorama()
		}
	}
	
	componentWillReceiveProps (newProps) {
		if (newProps.logoutWarning && newProps.logoutWarning!==this.props.logoutWarning) {
			this.props.onUpdateModal(true,'sessionExpiration');
		}
	}



	cancelModal = () => {
		this.props.onUpdateModal(false,'');
	}

	activateModal = (obj) => {
		this.props.onUpdateModal(true,obj);
	}

	cancelPanorama = () => {
		this.props.onUpdatePanorama(false,'');
		this.props.onUpdateModal(false,'');
	}

	activatePanorama = (obj) => {
		this.props.onUpdatePanorama(true,obj);
	}

	startExplanation = (obj) => {
		this.props.onUpdateModal(true,'messageSend');
	}

	render () {

		let spin = null;
		if (this.props.spin || this.props.loading) {
			spin = <Spinner/>
		}

		let showInModal = <Login/>;
		if (this.props.modwhat==='deleteConfirm') {
			showInModal = <DeleteConfirmation />
		} else if (this.props.modwhat==='sessionExpiration') {
			showInModal = <LogoutWarning />
		} else if (this.props.modwhat==='messageSend') {
			showInModal = <MessageSend formType={this.props.messageSendStatus} closePanorama={this.cancelPanorama}/>
		}

		let showInPano = null;
		if (this.props.panowhat==='panorama1') {
			showInPano = <Pannellum
				width='100%'
				height='100%'
				imagePath='/img/pano-01.jpg'
				isDisplayCloseButton={"visible"}
				showZoomCtrl={false}
				showFullscreenCtrl={false}
				autoLoad />
		} else if (this.props.panowhat==='panorama2') {
			showInPano = <Pannellum
				width='100%'
				height='100%'
				imagePath='/img/pano-02.jpg'
				isDisplayCloseButton={"visible"}
				showZoomCtrl={false}
				showFullscreenCtrl={false}
				autoLoad />
		} else if (this.props.panowhat==='panorama3') {
			showInPano = <Pannellum
				width='100%'
				height='100%'
				imagePath='/img/pano-03.jpg'
				isDisplayCloseButton={"visible"}
				showZoomCtrl={false}
				showFullscreenCtrl={false}
				autoLoad />
		}

		return (
			<Hoc>
				<div className={styles.spinnerHolder}>
					{spin}
				</div>
				<Modal show={this.props.showmod} modalClosed={this.cancelModal}>
					{showInModal}
				</Modal>
				<PanoramaHolder show={this.props.showpano} modalClosed={this.cancelPanorama} activateExplanationModal={this.startExplanation}>
					{showInPano}
				</PanoramaHolder>
				<BackToTop 
					position={this.props.lpPosition}
					returnToTop={this.returnLandingPageToTop}
				/>

				<SideDrawer 
					menuLinks={this.state.menuLinks}
				/>

				<Toolbar 
					menuLinks={this.state.menuLinks} realPath={this.props.curPath}
				/>
				<main>
					{this.props.children}
				</main>
			</Hoc>
		);
	}
}



const mapStateToProps = state => {
	return {
		showmod: state.my.showModal,
		modwhat: state.my.showInModal,
		showpano: state.my.showPanorama,
		panowhat: state.my.showInPanorama,
		panoNr: state.my.panoramaNumber,

		loading: state.auth.loading,
		spin: state.comm.loading,

		sideState: state.my.showSideDrawer,
		lpPosition: state.my.landingPagePosition,
		isAuthenticated: state.auth.token !== null,
		logoutWarning: state.auth.logoutWarning,

		messageSendStatus: state.comm.status,
	};
}

const mapDispatchToProps = dispatch => {
	return {
		onUpdateModal: (modState, modWhat) => dispatch( actions.changeModal(modState, modWhat) ),
		onUpdatePanorama: (panoState, panoWhat) => dispatch( actions.changePano(panoState, panoWhat) ),
		onUpdateSideDrawer: (sideState) => dispatch ( actions.changeSidedrawer(sideState) ),
		onUpdateLPPosition: (lpPos) => dispatch ( actions.changeLandingPagePosition(lpPos) ),
		onLogout: () => dispatch (actions.logout() )
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));